export const QUERY_KEY = {
  getAuthThunkRequest: 'getAuthThunkRequest',
  getProductsColumn: 'getProductsColumn',
  getProductsAuths: 'getProductsAuths',
  getProductId: 'getProductId',
  getDashboardSummary: 'getDashboardSummary',
  getAuthenticationsTabletProduct: 'getAuthenticationsTabletProduct',
  getDashboardBannerProduct: 'getDashboardBannerProduct',
  getResultStatuses: 'getResultStatuses',
  getDashboard: 'getDashboard',
  getNotification: 'getNotification',
  getAccountInfo: 'getAccountInfo',
  getInvoicesUser: 'getInvoicesUser',
  getProductTypeAuthenticate: 'getProductTypeAuthenticate',
  useGetBrandsListQuery: 'useGetBrandsListQuery',
  getCategoryListAuthenticate: 'getCategoryListAuthenticate',
  getProductTypesAuthenticate: 'getProductTypesAuthenticate',
  getModelsAuthenticate: 'getModelsAuthenticate',
  getAnswerTimeAuthenticate: 'getAnswerTimeAuthenticate',
  postCreateProductMutate: 'postCreateProductMutate',
  postCreatePhoto: 'postCreatePhoto',
  postUpdatePhoto: 'postUpdatePhoto',
  getLogoForCertificates: 'getLogoForCertificates',
  useGetBrandQuery: 'useGetBrandQuery',
  useGetBrandIdQuery: 'useGetBrandIdQuery',
  cartTotalMutate: 'cartTotalMutate',
  postInvoices: 'postInvoices',
  deleteLogoForCertificates: 'deleteLogoForCertificates',
  getStatistics: 'getStatistics',
  getNotificationsSetting: 'getNotificationsSetting',
  getSubscriptions: 'getSubscriptions',
  getUserSubscriptions: 'getUserSubscriptions',
  getCertificateTemplates: 'getCertificateTemplates',
  getUserCertificateTemplates: 'getUserCertificateTemplates',
  createCertificatesTemplates: 'createCertificatesTemplates',
  defaultTemplate: 'defaultTemplate',
  getFreeCustomTemplate: 'getFreeCustomTemplate',
  getUserBusinessInfo: 'getUserBusinessInfo',
  createUserBusiness: 'createUserBusiness',
  uploadingDocumentFileMutate: 'uploadingDocumentFileMutate',
  getCheckStatuses: 'getCheckStatuses',
  useCreateCertificateMutation: 'useCreateCertificateMutation',
  getBalanceConversationInfo: 'getBalanceConversationInfo',
  getUserTransactionsCreditsInfo: 'getUserTransactionsCreditsInfo',
  getBalanceUserInfo: 'getBalanceUserInfo',
  getAuthenticationPrice: 'getAuthenticationPrice',
  registerUser: 'registerUser',
  getCartInfo: 'getCartInfo',
  checkDiscountCode: 'checkDiscountCode',
  getCreditsBundles: 'getCreditsBundles',
  patchUserInfo: 'patchUserInfo',
  CheckOutSubscriptionPlans: 'CheckOutSubscriptionPlans',
  PromoPackagesActivate: 'PromoPackagesActivate',
  getCertificatesPriceMaxValue: 'getCertificatesPriceMaxValue',
  userUTMMutate: 'userUTMMutate',
  useCheckAuthQuery: 'useCheckAuthQuery',
  useLoginMutate: 'useLoginMutate',
  forgotEmailMutate: 'forgotEmailMutate',
  forgotPasswordMutate: 'forgotPasswordMutate',
  resetPasswordMutate: 'resetPasswordMutate',
  updateMeMutate: 'updateMeMutate',
  getCountries: 'getCountries',
  UploadRequestPhotoMutate: 'UploadRequestPhotoMutate',
  UpdateProductMutate: 'UpdateProductMutate',
  useCancelUnableProvideMutate: 'useCancelUnableProvideMutate',
  useRefreshTokenMutate: 'useRefreshTokenMutate',
  useCreateDraftMutate: 'useCreateDraftMutate',
  useDeleteDraftMutate: 'useDeleteDraftMutate',
  useGetModelsQuery: 'useGetModelsQuery',
  useProductTypesQuery: 'useProductTypesQuery',
  useUpdateCertificateMutate: 'useUpdateCertificateMutate',
  setSurveyAnswersMutate: 'setSurveyAnswersMutate',
  OffFullProfileInfoMutate: 'OffFullProfileInfoMutate',
  useCreateQRLabelMutate: 'useCreateQRLabelMutate',
  getBundleAndCertificatePrices: 'getBundleAndCertificatePrices',
  useGetCSVMutate: 'useGetCSVMutate',
  postOrderInvoices: 'postOrderInvoices',
  useUpdateDraftMutate: 'useUpdateDraftMutate',
  getCountryStates: 'getCountryStates',
  getFineProducts: 'getFineProducts',
  useProductByIdQuery: 'useProductByIdQuery',
  getProductLine: 'getProductLine',
  useChangeEmailMutate: 'useChangeEmailMutate',
  useUpdateUserAvatarMutate: 'useUpdateUserAvatarMutate',
  useRemoveUserAvatarMutate: 'useRemoveUserAvatarMutate',
  useConfirmEmailMutate: 'useConfirmEmailMutate',
  useResendDigitCodeMutate: 'useResendDigitCodeMutate',
  useGetUserLoyaltyInfo: 'useGetUserLoyaltyInfo',
  useGetUserLoyaltySmallInfo: 'useGetUserLoyaltySmallInfo',
  usePromoPackagesActivateMutate: 'usePromoPackagesActivateMutate',
  useGetLocale: 'useGetLocale',
  useUpdateLocaleMutate: 'useUpdateLocaleMutate',
  useNotificationReachingLoyaltyLevelShownMutate: 'useNotificationReachingLoyaltyLevelShownMutate',
  useNotificationAboutAccrualLoyaltyBonusesShownMutate: 'useNotificationAboutAccrualLoyaltyBonusesShownMutate',
  useCreateDraftAutoMutate: 'useCreateDraftAutoMutate',
  useUpdateDraftAutoMutate: 'useUpdateDraftAutoMutate',
  useGetBrandCoverageInfo: 'useGetBrandCoverageInfo',
  useUpgradeCertificateToCustomMutate: 'useUpgradeCertificateToCustomMutate',
  useAddWrittenStatementMutate: 'useAddWrittenStatementMutate',
  useGetSurveys: 'useGetSurveys',
  useGetBusinessTypesQuery: 'useGetBusinessTypesQuery',
  useProductLineByIdMutate: 'useProductLineByIdMutate',
} as const;

export enum AuthPageType {
  all = 'all',
  photosNeeded = 'photos-needed',
  underReview = 'under-review',
  completed = 'completed',
  drafts = 'drafts',
  dashboard = 'dashboard',
}

export enum SettingPageType {
  generalInfo = 'general-info',
  billingInfo = 'billing-info',
  receiptsAndInvoices = 'receipts-and-invoices',
  notifications = 'notifications',
  subscriptions = 'subscriptions',
  certificate = 'certificate',
}

export enum NotificationPageType {
  viewed = 'viewed',
  notViewed = 'notViewed',
}
