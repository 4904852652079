import { SurveyQuestionType } from '../../../../api/product/type';

/**
 * ID for first survey.
 *
 * @type {string}
 * */
export const SURVEY_ID: string = '21c7d46a-99e3-4d97-bfcf-9100dcda259f';

/**
 * ID for question 'To give you the best experience, please choose the option that describes you best'.
 *
 * @type {string}
 * */
export const DESCRIBES_QUESTION_ID: string = '732c965a-4562-4a42-8c47-9b5e6b7469d5';

/**
 * ID for question 'On a scale from 1 to 10, how likely are you to recommend LegitGrails to a friend or colleague?'.
 *
 * @type {string}
 * */
export const RECOMMEND_QUESTION_ID: string = '5d723abc-90b1-40c4-a397-e5f4d3454e2d';

/**
 * ID for question 'Would you be open to occasional interviews, surveys, or research activities to help us build better
 * features for you?'.
 *
 * @type {string}
 * */
export const SURVEYS_QUESTION_ID: string = 'fecba256-32d4-40a8-9998-c60ca6d1a5aa';

export const npsData: SurveyQuestionType[] = [
  {
    id: RECOMMEND_QUESTION_ID,
    text: 'On a scale from 1 to 10, how likely are you to recommend LegitGrails to a friend or colleague?',
    answers: [],
  },
  {
    id: SURVEYS_QUESTION_ID,
    text: 'Would you be open to occasional interviews, surveys, or research activities to help us build better features for you?',
    answers: ['Yes', 'No'],
  },
];
