import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { setNotificationAboutAccrualLoyaltyBonusesShown } from '../UserLoyaltyInfo';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useNotificationAboutAccrualLoyaltyBonusesShownMutate = () => {
  const dispatch = useDispatch();
  const client = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEY.useNotificationAboutAccrualLoyaltyBonusesShownMutate],
    mutationFn: async () => {
      const data = await setNotificationAboutAccrualLoyaltyBonusesShown();

      return data;
    },

    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEY.useGetUserLoyaltySmallInfo] });
    },

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
