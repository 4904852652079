import styled from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';

const CustomCertificateStyled = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 10px;
  background-color: ${COLOR_MAP.background.brandColor10};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 16px;
    gap: 12px;
  }
`;

const WrapperTitleStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export { CustomCertificateStyled, WrapperTextStyled, WrapperTitleStyled };
