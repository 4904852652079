/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountInfoQuery } from '../../../api/auth/hooks';
import { useNotificationQuery } from '../../../api/notifications';
import { useGetStatistics } from '../../../api/product/hooks/useGetStatistics';
import { useGetUserSubscriptions } from '../../../api/subscriptions/hooks/useGetUserSubscriptions';
import { useBalanceUserInfoQuery } from '../../../api/userCredits/hooks';
import {
  AcademyIcon,
  AuthenticationsIcon,
  BalanceIcon,
  DashboardIcon,
  HelpCenterIcon,
} from '../../../common/icons/SvgInline/SvgIcons_9';
import { TermsBlock } from '../../../components';
import { getFilteredNotifications } from '../../../helpers';
import { useLanguageSwitcherButton, useSelectedLanguage } from '../../../hooks';
import { ButtonBlock, IsNewUser, OrderStatistics, SectionBlock } from '../components';
import { Title } from '../components/Title';
import { UserArea } from '../components/UserArea';
import {
  ColumnBlockOneStyled,
  ColumnBlockTwoStyled,
  ContainerColumnMenu,
  HorizonLine,
  MenuWrapper,
  WrapperNavigateBlockStyled,
} from './styles';

type Props = {
  isSidebar?: boolean;
  closeHandler?: () => void;
};

export const ColumnMenu = ({ isSidebar, closeHandler }: Props) => {
  const { MenuLanguageSwitcher } = useLanguageSwitcherButton();
  const { t } = useTranslation();

  const selectedLang = useSelectedLanguage();

  const { data: dataUserSubscriptions } = useGetUserSubscriptions();
  const { data: statisticsData } = useGetStatistics();
  const { data: dataBalanceUserInfo } = useBalanceUserInfoQuery();
  const { isLoading: isLoadingAccountInfo, data: dataAccountInfo } = useAccountInfoQuery(null, true);

  const { data } = useNotificationQuery();
  const products = getFilteredNotifications(data?.notifications);

  const [hasNotification, setHasNotification] = useState(false);

  const isSubscriptions = dataUserSubscriptions?.result;

  useEffect(() => {
    setHasNotification(Boolean(products?.length));
  }, [products]);

  const [fixedPosition, setFixedPosition] = useState(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const firstColumnHeight = menuWrapperRef.current?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (firstColumnHeight && windowHeight) {
        setFixedPosition(windowHeight > firstColumnHeight);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const userAvatarImg = dataAccountInfo?.avatarUrl || null;
  const userAccountName = dataAccountInfo?.firstName || null;

  const isBlockAuthentications = Boolean(
    statisticsData?.photosNeed || statisticsData?.underReview || statisticsData?.completed,
  );

  return (
    <MenuWrapper isSidebar={isSidebar}>
      <ContainerColumnMenu isSidebar={isSidebar} fixedPosition={fixedPosition} ref={menuWrapperRef}>
        <ColumnBlockOneStyled>
          <Title
            closeHandler={closeHandler}
            isSubscriptions={isSubscriptions}
            isSidebar={isSidebar}
            hasNotification={hasNotification}
            userAvatarImg={userAvatarImg}
            isLoadingAccountInfo={isLoadingAccountInfo}
            userAccountName={userAccountName}
          />

          <WrapperNavigateBlockStyled>
            <SectionBlock text={t('baseLayout.columnMenu.dashboard')} Icon={DashboardIcon} to="/dashboard" />

            <SectionBlock
              isDisabled={!isBlockAuthentications}
              text={t('baseLayout.columnMenu.authentications')}
              Icon={AuthenticationsIcon}
              to="/authentications/all"
            />

            <OrderStatistics idExternalData={false} externalData={statisticsData} design="column" />

            <SectionBlock
              isBalance
              creditsVolume={dataBalanceUserInfo?.balanceInfo.creditsVolume}
              text={t('baseLayout.columnMenu.balance')}
              Icon={BalanceIcon}
              to="/balance"
            />

            <SectionBlock
              text={t('baseLayout.columnMenu.helpCenter')}
              Icon={HelpCenterIcon}
              href={`https://legitgrails.frontkb.com/${selectedLang.toLowerCase()}/categories/604994-legitgrails-account`}
            />

            <SectionBlock
              text={t('baseLayout.columnMenu.academy')}
              Icon={AcademyIcon}
              href="https://legitgrails.getlearnworlds.com"
            />

            <HorizonLine />
          </WrapperNavigateBlockStyled>
        </ColumnBlockOneStyled>

        <ColumnBlockTwoStyled>
          <ButtonBlock />

          <UserArea />

          <MenuLanguageSwitcher />

          <TermsBlock />
        </ColumnBlockTwoStyled>
      </ContainerColumnMenu>

      <IsNewUser />
    </MenuWrapper>
  );
};
