import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';

const PreviouslyUploadedLogosStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;

export { PreviouslyUploadedLogosStyled };
