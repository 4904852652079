import styled from 'styled-components';
import { truthfulAdaptive } from '../../componentUI/screen';

const WrapperBreadCrumbs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const WrapperLinksContainer = styled.div`
  display: flex;
`;

const WrapperArrow = styled.div`
  justify-content: center;
  align-items: center;
  margin: 0 8px;

  ${truthfulAdaptive.maxWidth.mobile} {
    margin: 0 4px;
  }
`;

const LinkStyle = styled.div`
  display: flex;
  align-items: center;
`;

export { LinkStyle, WrapperArrow, WrapperBreadCrumbs, WrapperLinksContainer };
