import React from 'react';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import { ReactComponent as ErrorPage404icon } from './assets/ErrorPage404icon.svg';

import { LogoMain } from '../../common/images';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Picture } from '../../componentUI/complex/Picture';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../componentUI/simple/Title';
import { useMatchMedia } from '../../hooks';
import { TermsBlock } from '../TermsBlock';
import { ButtonSubmit } from '../../modules/AuthRedisign/styled';
import { Container, ContentContainer, DescriptionScreen, IconWrapper } from './styled';

export const ErrorPage404 = () => {
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  return (
    <Container>
      <DescriptionScreen>
        <Picture url={LogoMain} width={isMobile ? 48 : 72} height={isMobile ? 48 : 72} />
        <ContentContainer>
          <IconWrapper>
            <ErrorPage404icon />
          </IconWrapper>

          <Title
            colorText={COLOR_MAP.text.brandColor}
            size={TITLE_SIZE.h2}
            margins={[0, 0, 8, 0]}
            style={{ textAlign: 'center' }}
          >
            Page not found
          </Title>

          <Title
            weight={TITLE_WEIGHT.normal}
            colorText={COLOR_MAP.text.white}
            size={TITLE_SIZE.h5}
            margins={[0, 0, 24, 0]}
            style={{ textAlign: 'center' }}
          >
            Something went wrong...
          </Title>

          <div style={{ height: '2px', minWidth: '80px', margin: '0 0 20px 0', backgroundColor: '#888888' }} />

          <div style={{ maxWidth: '280px', width: '100%' }}>
            <ButtonSubmit
              onClick={() => {
                navigate('/');
              }}
              isStretch
            >
              GO BACK
            </ButtonSubmit>
          </div>
        </ContentContainer>

        <TermsBlock />
      </DescriptionScreen>
    </Container>
  );
};
