import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CreditIcon, CreditIconV2 } from '../../../../common/icons/SvgInline/SvgIcons_10';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import {
  CreditsStyled,
  SelectionIndicatorStyled,
  TitleWrapperAnchorStyled,
  TitleWrapperLinkStyled,
  TitleWrapperStyled,
  WrapperIconStyled,
} from './styled';

type Props = {
  Icon: ({ size, color }: { size?: string | undefined; color?: string | undefined }) => JSX.Element;
  text: string;
  to?: string;
  href?: string;
  isDisabled?: boolean;
  isBalance?: boolean;
  creditsVolume?: number;
  isActiveBalanceLink?: Dispatch<SetStateAction<boolean>>;
};

export const SectionBlock = ({
  Icon,
  text,
  to,
  href,
  isDisabled,
  isBalance,
  creditsVolume,
  isActiveBalanceLink,
}: Props) => {
  const { pathname } = useLocation();
  const [isActiveLink, setIsActiveLink] = useState(false);

  const arrAddressBarValues = pathname.split('/').slice(1);

  useEffect(() => {
    const isActive = arrAddressBarValues.includes(text.toLowerCase());
    setIsActiveLink(isActive);

    if (isActiveBalanceLink) {
      isActiveBalanceLink(isActive);
    }
  }, [pathname]);

  if (isDisabled) {
    return (
      <TitleWrapperStyled>
        <SelectionIndicatorStyled isActiveLink={isActiveLink} />

        <WrapperIconStyled>
          <Icon color={COLOR_MAP.text.whiteDisabled} />
        </WrapperIconStyled>

        <Title size={TITLE_SIZE.h6} colorText={COLOR_MAP.text.whiteDisabled}>
          {text}
        </Title>
      </TitleWrapperStyled>
    );
  }

  const content = (
    <>
      <SelectionIndicatorStyled isActiveLink={isActiveLink} />

      <WrapperIconStyled>
        {isActiveLink ? <Icon color={COLOR_MAP.text.darkGrey} /> : <Icon color={COLOR_MAP.text.white} />}
      </WrapperIconStyled>

      <Title size={TITLE_SIZE.h6} colorText={isActiveLink ? COLOR_MAP.text.darkGrey : COLOR_MAP.text.white}>
        {text}
      </Title>

      {isBalance && (
        <CreditsStyled isActiveLink={isActiveLink}>
          {isActiveLink ? <CreditIconV2 size="18" /> : <CreditIcon size="18" />}
          <Text
            weight={TEXT_WEIGHT.bold}
            size={TEXT_SIZE.sub}
            colorText={isActiveLink ? COLOR_MAP.text.brandColor : COLOR_MAP.text.white}
          >
            {creditsVolume}
          </Text>
        </CreditsStyled>
      )}
    </>
  );

  if (href) {
    return (
      <TitleWrapperAnchorStyled href={href} target="_blank" rel="noopener noreferrer">
        {content}
      </TitleWrapperAnchorStyled>
    );
  }

  return <TitleWrapperLinkStyled to={to || '#'}>{content}</TitleWrapperLinkStyled>;
};
