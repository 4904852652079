import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { getCertificatesTemplates } from '../index';

import { addToast } from '../../../redux/reducers/toast-reducer';

export const useCertificateTemplatesQuery = (enabled = true) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getCertificateTemplates],
    queryFn: async () => {
      const { data } = await getCertificatesTemplates();

      return data;
    },
    enabled,
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
