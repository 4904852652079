import styled, { css } from 'styled-components';
import { LinkRouter } from '../../../../componentUI/simple/Link';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';
import { TransparentButton } from '../../../../componentUI/simple/Button';

const linkButtonStyles = css`
  max-height: 79px;

  padding: 10px 12px 10px 16px;

  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: auto;
  gap: 16px;

  justify-items: flex-start;
  align-items: center;

  text-align: start;

  border-radius: 6px;

  box-shadow: 0 4px 9px rgba(150, 112, 38, 0.05), 0 5px 14px rgba(0, 0, 0, 0.08);

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 20px 12px;

    max-height: 160px;
    width: clamp(128px, 32vw, 310px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    flex-shrink: 0;
  }
`;

export const StyledLinkRouter = styled(LinkRouter)<{ bgColor: string }>`
  ${linkButtonStyles};

  background: ${({ bgColor }) => bgColor};
  text-decoration: none;
  color: inherit;
`;

export const StyledLinkClassic = styled.a<{ bgColor: string }>`
  ${linkButtonStyles};

  background: ${({ bgColor }) => bgColor};
  text-decoration: none;
  color: inherit;
`;

export const StyledButton = styled(TransparentButton)<{ bgColor: string }>`
  &&& {
    ${linkButtonStyles};

    background: ${({ bgColor }) => bgColor};

    & path {
      stroke: ${COLOR_MAP.controls.fillNormal};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;

  background-color: ${COLOR_MAP.background.white};
  opacity: 0.2;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  ${truthfulAdaptive.maxWidth.tablet} {
    align-items: center;
    text-align: center;
  }
`;
