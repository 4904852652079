import { MutableRefObject, useEffect, useState } from 'react';

const useOnScreen = (
  ref: MutableRefObject<null>,
  rootMargin = '100% 0% 100% 0%',
): [isVisible: boolean, fieldView: number] => {
  const [isVisible, setIsVisible] = useState(false);
  const [fieldView, setFieldView] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        setFieldView(entry.intersectionRatio);
      },
      {
        rootMargin,
        threshold: [0, 1],
      },
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  });

  return [isVisible, fieldView];
};

export { useOnScreen };
