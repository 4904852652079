import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getCheckAuth } from '../index';
import { ErrorType } from './types';

export const useCheckAuthQuery = (jwtToken: string | null) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: [QUERY_KEY.registerUser],
    queryFn: async () => {
      const { data } = await getCheckAuth(jwtToken || Cookies.get('jwt'));

      return data;
    },
    enabled: Boolean(jwtToken || Cookies.get('jwt')),
    onError: (error: ErrorType) => {
      // ошибку Unauthorized, пользователю не показываем
      if (error?.response?.data?.message !== 'Unauthorized') {
        dispatch(
          addToast({
            text: error?.response?.data?.message || 'Something is wrong, please try again.',
          }),
        );
      }
    },
  });
};
