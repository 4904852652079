import styled, { CSSProperties } from 'styled-components';

const StartingInformationStyled = styled.div`
  padding: 0 0 40px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const WrapperButtonV2Styled = styled.div`
  width: 100%;
  max-width: 260px;
`;

const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
};

export { StartingInformationStyled, stylesForButtons, WrapperButtonV2Styled };
