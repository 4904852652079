import { AuthPageType } from '../../../common/constants';

const DEFAULT_FILTER_VALUES = {
  statusFilter: false,
  dateFilter: false,
  answerFilter: false,
  outcomeFilter: false,
};

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

/** Содержит список столбцов для таблицы
 *  AuthenticationsTablet доступных к сортировки
 * в зависимости от расположения таблицы на различных страницах сайта */

export const creatingFilterColumns = (currentPage: string | undefined) => {
  switch (currentPage) {
    case all:
      return { ...DEFAULT_FILTER_VALUES, ...{ statusFilter: true, dateFilter: true } };
    case photosNeeded:
      return { ...DEFAULT_FILTER_VALUES, ...{ dateFilter: true } };
    case underReview:
      return { ...DEFAULT_FILTER_VALUES, ...{ dateFilter: true } };
    case completed:
      return { ...DEFAULT_FILTER_VALUES, ...{ dateFilter: true, outcomeFilter: true } };
    case drafts:
      return { ...DEFAULT_FILTER_VALUES, ...{ dateFilter: true } };
    case dashboard:
      return DEFAULT_FILTER_VALUES;
    default:
      return DEFAULT_FILTER_VALUES;
  }
};
