import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { GetResendDigitCodeRequestType } from '../authTypes';
import { getResendDigitCode } from '../index';
import { ErrorType } from './types';

export const useResendDigitCodeMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.useConfirmEmailMutate],
    mutationFn: async (email: GetResendDigitCodeRequestType) => {
      const { data } = await getResendDigitCode(email);

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
