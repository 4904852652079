import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { NotificationType } from '../../../componentUI/types';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getLogoForCertificates } from '../index';
import { LogoForCertificateType } from '../types';

export const useLogoForCertificates = (enabled = true) => {
  const dispatch = useDispatch();

  return useQuery<Array<LogoForCertificateType>>({
    queryKey: [QUERY_KEY.getLogoForCertificates],
    queryFn: async () => {
      const { data } = await getLogoForCertificates();

      return data;
    },
    enabled,
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
          type: NotificationType.Default,
        }),
      );
    },
  });
};
