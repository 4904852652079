/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { useGetModelsQuery } from '../../api/models/hooks/useGetModelsQuery';

import { sortArrayOfObjectsForAscByField } from '../../helpers/sortArrayOfObjectsForAscByField';

import { useMatchMedia, useSelectedLanguage } from '../../hooks';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { SkeletonBasic } from '../../componentUI/skeleton';

import { ModelSelector } from './ModelSelector';
import { NewModelInfo } from './NewModelInfo';

import { OptionsModelsType, Props, SelectedModelsType } from './types';

// SelectedModelCategory - компонент в котором содержится список
// моделей для выбранной категории или поле для ввода вручную
// если список моделей отсутствует

// setSelectedModel(null) - обнуление выбранного значения в
// selectedModel при выборе новой категории бренда, так же
// обнуляется и при выборе и категории
// setModelName('') - обнуление выбранного значения в modelName
// необходимо обнулять т.к. не во всех брендах есть список моделей
// и пользователь так же может ввести модель вручную

// т.к. модуль SelectedModelCategory общий для нескольких форм
// необходим внешний стейт setExternalOptionsModels
// (входной параметр в модуле AuthenticateV2 - setOptionsModels) для корректной
// работы функционала заполнения Draft, если у выбранной категории есть выпадающий список

// если список моделей есть, то добавляем обязательные
// пункты not sure и other в начало списка
const MANDATORY_ITEMS = [
  {
    key: 'Other',
    value: 'Other',
    label: 'Other',
    name: 'Other',
    jpegUrl: 'Other',
  },
  {
    key: 'Not Sure',
    value: 'Not Sure',
    label: 'Not Sure',
    name: 'Not Sure',
    jpegUrl: 'Not Sure',
  },
];

// modelNameProductCard - содержит информацию о ранее выбранном пользователем
// значении для поля Model с карточки заказа, используется для предзаполнения выпадающего списка
// на форме изменения модели в функционале перевыпуска сертификата
// используется только там, в заполнении карточки из черновика не участвует

/** Блок отвечающий содержащий функционал выбора модели для заказа */

export const SelectedModelCategory = ({
  setInputModelName,
  inputModelName,
  setSelectedModelName,
  selectedModelName,
  brandId,
  productTypeId,
  setExternalOptionsModels,
  draftModelName,
  handleModelNameChange,
  isDraftModel,
  setSelectedModelNameId,
  setIsSelectorModels,
  setIsRequiredModelName,
}: Props) => {
  const { isMobile } = useMatchMedia();
  const selectedLanguage = useSelectedLanguage();

  const [isDisabledModelSelector, setIsDisabledModelSelector] = useState(false);
  const [isDisabledModelInput, setIsDisabledModelInput] = useState(true);

  // компонент Select принимает в props value только тот же тип, что до этого принят им в options
  // данный стейт создан именно для этого, используется ТОЛЬКО в этом модуле в угоду TS
  const [modelNameSelect, setModelNameSelect] = useState<SelectedModelsType | null>(null);

  const {
    data: dataModelsNames,
    isFetching: isFetchingModelsNames,
    isSuccess: isSuccessModelsNames,
    isError: isErrorModelsNames,
  } = useGetModelsQuery(
    {
      brandId: brandId || '',
      productTypeId: productTypeId || '',
      limit: 600,
      localeName: selectedLanguage,
    },
    Boolean(brandId) && Boolean(productTypeId),
  );

  const optionsModels: Array<OptionsModelsType> | null = useMemo(() => {
    if (isSuccessModelsNames && dataModelsNames.length > 0) {
      const optionsModelsBrand = dataModelsNames.map((element) => {
        return {
          key: element.id,
          value: element.id,
          label: element.displayName,
          name: element.displayName,
          jpegUrl: element.imageUrl || null,
        };
      });

      const sortOptionsModelsBrand = optionsModelsBrand.sort(sortArrayOfObjectsForAscByField('label'));

      return [...MANDATORY_ITEMS, ...sortOptionsModelsBrand];
    }

    if (isSuccessModelsNames && dataModelsNames.length === 0) {
      return [];
    }

    return null;
  }, [dataModelsNames]);

  useEffect(() => {
    if (isDraftModel && draftModelName) {
      const modelNamePreFilled = optionsModels?.find(
        (element) => element.name.toLowerCase() === draftModelName.toLowerCase(),
      );

      if (optionsModels && optionsModels.length <= 0 && !modelNamePreFilled && handleModelNameChange) {
        handleModelNameChange('input');
        setIsDisabledModelInput(false);
        setIsDisabledModelSelector(true);
      }

      if (optionsModels && optionsModels.length > 0 && modelNamePreFilled && handleModelNameChange) {
        handleModelNameChange('selector');
        setIsDisabledModelInput(true);
        setIsDisabledModelSelector(false);
      }

      if (optionsModels && optionsModels.length > 0 && !modelNamePreFilled && handleModelNameChange) {
        handleModelNameChange('other');
        setIsDisabledModelInput(false);
        setIsDisabledModelSelector(false);
      }

      setModelNameSelect(
        modelNamePreFilled || {
          key: 'Other',
          value: 'Other',
          label: 'Other',
          name: 'Other',
          jpegUrl: 'Other',
        },
      );
      return;
    }

    if (optionsModels && optionsModels.length > 0) {
      setIsDisabledModelInput(true);
      setIsDisabledModelSelector(false);
      setInputModelName(null);
      setSelectedModelName(null);
      if (setSelectedModelNameId) {
        setSelectedModelNameId(null);
      }
      return;
    }

    if (optionsModels && optionsModels.length <= 0) {
      setIsDisabledModelInput(false);
      setIsDisabledModelSelector(true);
      setInputModelName(null);
      setSelectedModelName(null);
      if (setSelectedModelNameId) {
        setSelectedModelNameId(null);
      }
    }
  }, [optionsModels]);

  useEffect(() => {
    if (isErrorModelsNames) {
      setIsDisabledModelInput(false);
      setIsDisabledModelSelector(true);
      setInputModelName(null);
      setSelectedModelName(null);
      if (setSelectedModelNameId) {
        setSelectedModelNameId(null);
      }
    }
  }, [isErrorModelsNames]);

  useEffect(() => {
    setInputModelName(null);
    setSelectedModelName(null);
    setModelNameSelect(null);
  }, [brandId, productTypeId]);

  // если пользователь выбрал значение 'Not Sure' бэк ждет modelName === null
  // если пользователь выбрал значение 'Other' появляется поле ввода произвольного значения
  // во всех остальных случаях значение для  modelName === selectedModel.name
  useEffect(() => {
    if (selectedModelName === 'Not Sure') {
      setIsDisabledModelInput(true);
      setIsDisabledModelSelector(false);
      setInputModelName(null);
      setSelectedModelName(null);
      if (setSelectedModelNameId) {
        setSelectedModelNameId(null);
      }
      if (setIsSelectorModels) {
        setIsSelectorModels(false);
      }
      return;
    }

    if (selectedModelName === 'Other') {
      setIsDisabledModelInput(false);
      setIsDisabledModelSelector(false);
      setInputModelName(null);
      setSelectedModelName(null);
      if (setSelectedModelNameId) {
        setSelectedModelNameId(null);
      }
      if (setIsSelectorModels) {
        setIsSelectorModels(false);
      }
      return;
    }

    if (selectedModelName && selectedModelName !== 'Other') {
      setIsDisabledModelInput(true);
      setIsDisabledModelSelector(false);
    }
  }, [selectedModelName]);

  useEffect(() => {
    if (setExternalOptionsModels) {
      setExternalOptionsModels(optionsModels);
    }
  }, [optionsModels]);

  useEffect(() => {
    if (isSuccessModelsNames && dataModelsNames.length > 0) {
      if (setIsSelectorModels) {
        setIsSelectorModels(true);
      }
    } else if (setIsSelectorModels) {
      setIsSelectorModels(false);
    }
  }, [isSuccessModelsNames, dataModelsNames]);

  useEffect(() => {
    if (setIsRequiredModelName) {
      if (modelNameSelect?.value === 'Other' || modelNameSelect?.value === 'Not Sure') {
        setIsRequiredModelName(false);
        return;
      }

      setIsRequiredModelName(!isDisabledModelSelector);
    }
  }, [isDisabledModelSelector, modelNameSelect]);

  return (
    <>
      {isFetchingModelsNames && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <SkeletonBasic width="80px" height="18px" bgColor={COLOR_MAP.controls.skeleton.content1} />
          <SkeletonBasic
            width="100%"
            height={isMobile ? '68px' : '106px'}
            bgColor={COLOR_MAP.controls.skeleton.content2}
          />
        </div>
      )}

      {!isFetchingModelsNames && !isDisabledModelSelector && Boolean(productTypeId) && (
        <ModelSelector
          optionsModels={optionsModels}
          setSelectedModelName={setSelectedModelName}
          setSelectedModelNameId={setSelectedModelNameId}
          modelNameSelect={modelNameSelect}
          setModelNameSelect={setModelNameSelect}
        />
      )}

      {!isFetchingModelsNames && !isDisabledModelInput && Boolean(productTypeId) && (
        <NewModelInfo setInputModelName={setInputModelName} inputModelName={inputModelName} />
      )}
    </>
  );
};
