import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Hr } from '../../../../componentUI/simple/Hr';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { useFormField } from '../../../../hooks';
import { AddCertificateTemplate, CertificateLayout, LogoCertificate, TemplateName } from './components';
import { ManageTemplatesStyled } from './styled';
import { Props } from './types';

export const ManageTemplates = ({ isOpenAddCustomCertificate }: Props) => {
  const { t } = useTranslation();

  const [templateName, setTemplateName, templateNameError, setTemplateNameError] = useFormField({
    initValue: '',
    withError: true,
  });
  const [selectedLogoForCertificate, setSelectedLogoForCertificate] = useState('');
  const [selectedLayoutForCertificate, setSelectedLayoutForCertificate] = useState('');

  const isEnableAddBtnTemplate = Boolean(selectedLayoutForCertificate && templateName);

  return (
    <ManageTemplatesStyled>
      <Hr color={COLOR_MAP.background.separatorColorOne} width="100%" />

      <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
        {t('customCertificate.manageTemplates.title')}
      </Title>

      <TemplateName
        templateName={templateName}
        setTemplateName={setTemplateName}
        templateNameError={templateNameError}
        setTemplateNameError={setTemplateNameError}
      />

      <CertificateLayout
        setSelectedLayoutForCertificate={setSelectedLayoutForCertificate}
        selectedLayoutForCertificate={selectedLayoutForCertificate}
        isOpenAddCustomCertificate={isOpenAddCustomCertificate}
      />

      <LogoCertificate
        setSelectedLogoForCertificate={setSelectedLogoForCertificate}
        selectedLogoForCertificate={selectedLogoForCertificate}
        isOpenAddCustomCertificate={isOpenAddCustomCertificate}
      />

      <AddCertificateTemplate
        setSelectedLogoForCertificate={setSelectedLogoForCertificate}
        selectedLogoForCertificate={selectedLogoForCertificate}
        setSelectedLayoutForCertificate={setSelectedLayoutForCertificate}
        selectedLayoutForCertificate={selectedLayoutForCertificate}
        setTemplateName={setTemplateName}
        templateName={templateName}
        isEnableAddBtnTemplate={isEnableAddBtnTemplate}
      />
    </ManageTemplatesStyled>
  );
};
