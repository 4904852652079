import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getLocale } from '../index';
import { ErrorType } from './types';

export const useGetLocale = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.useGetLocale],
    mutationFn: async () => {
      const { data } = await getLocale();

      return data;
    },

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
