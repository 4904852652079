import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { NotificationType } from '../../../componentUI/types';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { deleteLogoForCertificates } from '../index';
import { LogoForCertificateType } from '../types';

export const useDeleteLogoForCertificatesMutate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((id: string) => deleteLogoForCertificates(id), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<LogoForCertificateType[]>([QUERY_KEY.getLogoForCertificates], (prevData) => {
        if (prevData) {
          return prevData.filter((item) => item.id !== id);
        }
        return prevData;
      });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 400) {
        dispatch(
          addToast({
            text: 'You cannot delete this logo as it is used in other certificates.',
            type: NotificationType.Default,
          }),
        );
      } else {
        dispatch(
          addToast({
            text: 'Something went wrong. Please try again.',
            type: NotificationType.Default,
          }),
        );
      }
    },
  });
};
