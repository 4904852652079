import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoMain } from '../../../../common/images';
import { Picture } from '../../../../componentUI/complex/Picture';
import { useMatchMedia } from '../../../../hooks';

export const LogoBlock = () => {
  const { isMobile } = useMatchMedia();
  const { t } = useTranslation();

  return <Picture url={LogoMain} width={isMobile ? 48 : 72} height={isMobile ? 48 : 72} alt={t('logoBlock.altText')} />;
};
