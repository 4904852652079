/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateMe } from '../../../../api/auth/auth-api';
import { useAccountInfoQuery } from '../../../../api/auth/hooks';
import { useGetBusinessTypesQuery } from '../../../../api/businessTypes/hooks';
import { useCountryStatesQuery } from '../../../../api/countryStates/hooks';
import { QUERY_KEY } from '../../../../common';
import { Button } from '../../../../componentUI/simple/Button';
import { ErrorInputReactSelectBlock } from '../../../../componentUI/simple/ErrorInputReactSelectBlock';
import { Text } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { NotificationType } from '../../../../componentUI/types';
import { useSelectedLanguage } from '../../../../hooks';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { StateType, TypeBusinessType } from '../../../AccountSettings/containers/BillingInfoBlock/types';
import { SelectWrapper, Wrapper } from './styled';
import { Props } from './type';

const DEFAULT_COUNTRY = {
  type: 'select',
  value: '2688aa4e-076c-4f53-bfc8-01f5a8e33a02',
  label: 'United States',
  isEU: null,
  postalRegex: '^\\d{5}(-\\d{4})?$',
};

export const UpdateInfoUsaModalContent = ({ toggleModal }: Props) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelectedLanguage();

  const { data: accountData } = useAccountInfoQuery(null, true);

  const {
    data: dataBusinessTypesQuery,
    isLoading: isLoadingBusinessTypesQuery,
    isError: isErrorBusinessTypesQuery,
  } = useGetBusinessTypesQuery({ localeName: selectedLanguage });

  const {
    data: dataCountryStates,
    isLoading: isLoadingCountryStates,
    isError: isErrorCountryStates,
  } = useCountryStatesQuery(DEFAULT_COUNTRY.value || '', true);

  const [selectedStateError, setSelectedStateError] = useState(true);
  const [selectedState, setSelectedState] = useState<StateType | null>(null);
  const [businessType, setBusinessType] = useState<TypeBusinessType | null>(null);
  const [typeBusinessError, setTypeBusinessError] = useState(true);

  const client = useQueryClient();
  const {
    mutate: updateUserData,
    isSuccess,
    isError,
    isLoading,
  } = useMutation({
    mutationFn: updateMe,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAccountInfo] });
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      toggleModal();
      dispatch(
        addToast({
          text: t('dashboard.updateInfoUsaModal.successMessage'),
          type: NotificationType.Success,
        }),
      );
    }
    if (isError) {
      dispatch(
        addToast({
          text: t('dashboard.updateInfoUsaModal.errorMessage'),
          type: NotificationType.Default,
        }),
      );
    }
  }, [isSuccess, isError]);

  const isButtonDisabled = useMemo(() => {
    if (isLoading) return true;

    const showState = accountData?.isUsaUserWithoutState;
    const showBusinessType = accountData?.isBusiness && !accountData?.businessType?.id;

    if (showState && showBusinessType) {
      return !selectedState || !businessType;
    }

    if (showState) {
      return !selectedState;
    }

    if (showBusinessType) {
      return !businessType;
    }

    return false;
  }, [isLoading, accountData, selectedState, businessType]);

  const confirmHandler = () => {
    const updateData: { countryStateId?: string; businessTypeId?: string } = {};

    if (selectedState?.value) {
      updateData.countryStateId = selectedState.value;
    }

    if (businessType?.value) {
      updateData.businessTypeId = businessType.value;
    }

    updateUserData(updateData);
  };

  return (
    <Wrapper>
      <Title size={TITLE_SIZE.h4}>{t('dashboard.updateInfoUsaModal.title')}</Title>

      <Text style={{ textAlign: 'center' }}>{t('dashboard.updateInfoUsaModal.message')}</Text>

      {accountData?.isUsaUserWithoutState && (
        <>
          <SelectWrapper>
            <Select
              placeholder={t('dashboard.updateInfoUsaModal.countryPlaceholder')}
              value={DEFAULT_COUNTRY}
              isDisabled
            />
          </SelectWrapper>

          <SelectWrapper>
            <ErrorInputReactSelectBlock
              isError={selectedStateError}
              errorMessage={t('dashboard.updateInfoUsaModal.stateError')}
            >
              <Select
                placeholder={t('dashboard.updateInfoUsaModal.statePlaceholder')}
                options={dataCountryStates}
                value={selectedState}
                isDisabled={isLoadingCountryStates || isErrorCountryStates}
                onChange={(options) => {
                  if (options) {
                    setSelectedState(options);
                    setSelectedStateError(!options.value);
                  }
                }}
              />
            </ErrorInputReactSelectBlock>
          </SelectWrapper>
        </>
      )}

      {accountData?.isBusiness && !accountData?.businessType?.id && (
        <SelectWrapper>
          <ErrorInputReactSelectBlock
            isError={typeBusinessError}
            errorMessage={t('dashboard.updateInfoUsaModal.businessTypeError')}
          >
            <Select
              isDisabled={isLoadingBusinessTypesQuery || isErrorBusinessTypesQuery}
              placeholder={t('dashboard.updateInfoUsaModal.businessTypePlaceholder')}
              options={dataBusinessTypesQuery}
              value={businessType}
              onChange={(options) => {
                if (options) {
                  setBusinessType(options);
                  setTypeBusinessError(!options.value);
                }
              }}
            />
          </ErrorInputReactSelectBlock>
        </SelectWrapper>
      )}

      <Button onClick={confirmHandler} disabled={isButtonDisabled} isStretch>
        {t('dashboard.updateInfoUsaModal.confirmButton')}
      </Button>
    </Wrapper>
  );
};
