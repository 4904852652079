import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';

import { ItemFulfilledRequestsStyled } from './styled';
import { ItemFulfilledRequestsTypes } from './type';

export const ItemFulfilledRequests = ({ text, quantity }: ItemFulfilledRequestsTypes) => {
  return (
    <ItemFulfilledRequestsStyled>
      <Title style={{ position: 'relative', top: '3px' }} colorText={COLOR_MAP.text.darkGrey} size={TITLE_SIZE.h3}>
        {quantity}
      </Title>

      <Text style={{ display: 'flex', alignSelf: 'flex-end' }} colorText={COLOR_MAP.text.darkGrey}>
        {text}
      </Text>
    </ItemFulfilledRequestsStyled>
  );
};
