import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from '../../../../../hooks';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { TAG_NAME } from '../../../../../componentUI/constants';
import { Text } from '../../../../../componentUI/simple/Text';
import { TitleBlock } from '../../TitleBlock';
import { SuccessfullyMailLogo } from '../../../assets';
import { LinkTextStyled, ScreenResetPassRequestSuccessStyled, WrapperSignInStyled, WrapperTextStyled } from './styled';
import { Props } from './types';

// setIsShopifyUser - используется для корректного возврата на страницу Sign In
// для пользователей которые ввели данные авторизации с портала Shopify

export const ScreenResetPassRequestSuccess = ({ setIsShopifyUser }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  return (
    <ScreenResetPassRequestSuccessStyled>
      <Picture url={SuccessfullyMailLogo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} />

      <TitleBlock
        titleText={t('auth.signInPage.screenResetPassRequestSuccess.title')}
        subtitleText={t('auth.signInPage.screenResetPassRequestSuccess.subtitle')}
      />

      <WrapperSignInStyled>
        <WrapperTextStyled>
          <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
            {t('auth.signInPage.screenResetPassRequestSuccess.goBackText')}&nbsp;
          </Text>

          <LinkTextStyled
            tag={TAG_NAME.span}
            colorText={COLOR_MAP.text.brandColor}
            onClick={() => {
              navigate('/signin');
              if (setIsShopifyUser) {
                setIsShopifyUser(false);
              }
            }}
          >
            {t('auth.signInPage.screenResetPassRequestSuccess.signIn')}
          </LinkTextStyled>
        </WrapperTextStyled>
      </WrapperSignInStyled>
    </ScreenResetPassRequestSuccessStyled>
  );
};
