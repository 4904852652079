import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { QUERY_KEY } from '../../../common';
import { SurveyPostDataType } from '../../product/type';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { updateSurveyAnswers } from '../survey-api';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

/**
 * Custom TanStack Query hook for updating survey answers.
 *
 * @returns {Object} An object containing the mutation function, success status, and mutation result data.
 *
 * @example
 *
 * const {
 *   mutate: UpdateSurveyAnswer,
 *   isSuccess: isSuccessUpdateSurveyAnswer,
 *   data: dataUpdateSurveyAnswer,
 * } = useUpdateSurveyAnswersMutate();
 *
 * UpdateSurveyAnswer(answer);
 *
 */
export const useUpdateSurveyAnswersMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.setSurveyAnswersMutate],
    mutationFn: async (surveyData: SurveyPostDataType) => {
      const { data } = await updateSurveyAnswers(surveyData);

      return data;
    },
    onError: (error: ErrorType) => {
      console.error(error?.response?.data?.message);

      dispatch(
        addToast({
          text: 'Server error. Please try again.',
        }),
      );
    },
  });
};
