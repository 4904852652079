import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';

const CertificateLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;

const LayoutTemplateStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${truthfulAdaptive.maxWidth.mobile} {
    justify-content: center;
  }
`;

const stylesBackgroundButtons: CSSProperties = {
  padding: '14px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  backgroundColor: COLOR_MAP.background.white,

  boxShadow: '0px 3px 5px 0px rgba(150, 112, 38, 0.04), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
  borderRadius: '8px',
};

export { CertificateLayoutStyled, LayoutTemplateStyled, stylesBackgroundButtons };
