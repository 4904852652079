/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUploadLogoForCertificatesMutate } from '../../../../../../api/file/hooks/useUploadLogoForCertificatesMutate';
import { LoaderCertificate, UploadPhotoIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_1';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../../../componentUI/constants';
import { BUTTON_SIZE, SecondButton } from '../../../../../../componentUI/simple/Button';
import { Text, TEXT_SIZE } from '../../../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../../../componentUI/simple/Title';
import { addToast } from '../../../../../../redux/reducers/toast-reducer';
import { PreviouslyUploadedLogos } from '../PreviouslyUploadedLogos';
import { getImageDimensions } from './helpers';
import { LogoCertificateStyled, stylesForButtons, UploadLogoStyled, WrapperSecondButtonStyled } from './styled';
import { Props } from './types';

export const LogoCertificate = ({
  selectedLogoForCertificate,
  setSelectedLogoForCertificate,
  isOpenAddCustomCertificate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    mutate: uploadLogo,
    isLoading: isLoadingUploadLogo,
    data: uploadLogoData,
  } = useUploadLogoForCertificatesMutate();

  useEffect(() => {
    if (uploadLogoData && uploadLogoData.id) {
      setSelectedLogoForCertificate(uploadLogoData.id);
    }
  }, [uploadLogoData]);

  const handleUploadPhotoClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/jpeg, image/jpg, image/png, image/heic';
    fileInput.click();

    fileInput.onchange = async () => {
      const file = fileInput.files && fileInput.files[0];

      if (file) {
        const { width, height } = await getImageDimensions(file);

        const validExtensions = /\.(jpg|jpeg|png|heic|heif)$/i;
        const fileSizeInMegabytes = 5;
        const validWidthAndHeight = 400;

        if (!file.name.match(validExtensions)) {
          dispatch(
            addToast({
              text: t('customCertificate.manageTemplates.logoCertificate.invalidFileFormat'),
            }),
          );
          return;
        }

        if (file.size > fileSizeInMegabytes * 1024 * 1024) {
          dispatch(
            addToast({
              text: t('customCertificate.manageTemplates.logoCertificate.fileSizeExceeded'),
            }),
          );
          return;
        }

        if (width > validWidthAndHeight || height > validWidthAndHeight) {
          dispatch(
            addToast({
              text: t('customCertificate.manageTemplates.logoCertificate.validWidthAndHeight'),
            }),
          );
          return;
        }

        uploadLogo(file);
      }
    };
  };

  return (
    <LogoCertificateStyled>
      <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
        {t('customCertificate.manageTemplates.logoCertificate.title')}
      </Title>

      <PreviouslyUploadedLogos
        setSelectedLogoForCertificate={setSelectedLogoForCertificate}
        selectedLogoForCertificate={selectedLogoForCertificate}
        isOpenAddCustomCertificate={isOpenAddCustomCertificate}
      />

      <UploadLogoStyled>
        <WrapperSecondButtonStyled>
          <SecondButton
            size={BUTTON_SIZE.extraSmall}
            style={stylesForButtons}
            onClick={handleUploadPhotoClick}
            disabled={isLoadingUploadLogo}
            isStretch
          >
            {isLoadingUploadLogo ? (
              <LoaderCertificate size="22" />
            ) : (
              <>
                <UploadPhotoIcon size="16" />
                {t('customCertificate.manageTemplates.logoCertificate.uploadButton')}
              </>
            )}
          </SecondButton>
        </WrapperSecondButtonStyled>

        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
          {t('customCertificate.manageTemplates.logoCertificate.photoUploadRestrictions')}
        </Text>
      </UploadLogoStyled>
    </LogoCertificateStyled>
  );
};
