import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { uploadLogoForCertificates } from '../index';
import { LogoForCertificateType } from '../types';

export const useUploadLogoForCertificatesMutate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    async (data: File) => {
      const formData = new FormData();
      formData.append('logo', data);

      const response = await uploadLogoForCertificates(formData);

      return response.data;
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          [QUERY_KEY.getLogoForCertificates],
          (prevData: LogoForCertificateType[] | undefined) => {
            if (prevData) {
              return [...prevData, response];
            }
            return [response];
          },
        );
      },
      onError: () => {
        dispatch(
          addToast({
            text: 'Something went wrong. Please try again.',
          }),
        );
      },
    },
  );
};
