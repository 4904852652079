import { DESCRIBES_QUESTION_ID, npsData, RECOMMEND_QUESTION_ID, SURVEYS_QUESTION_ID } from '../NpsContent/npsData';
import { SurveyQuestionType } from '../../../../api/product/type';

/**
 * Time interval after closing the modal, in milliseconds.
 *
 * @type {number}
 */
export const INTERVAL_AFTER_CLOSING: number = 3 * 24 * 60 * 60 * 1000; // 3 days

/**
 * Test time interval after closing the modal, in milliseconds (for testing purposes).
 *
 * @type {number}
 */
export const TEST_INTERVAL_AFTER_CLOSING: number = 60 * 1000; // 1 minute

/**
 * Cooldown interval for survey questions, in days.
 *
 * @type {number}
 */
export const COOLDOWN_INTERVAL: number = 180; // 180 days

/**
 * Test cooldown interval for survey questions, in days (for testing purposes).
 *
 * @type {number}
 */
export const TEST_COOLDOWN_INTERVAL: number = 5 / (24 * 60); // 5 minutes

/**
 * Number of milliseconds in a day.
 *
 * @type {number}
 */
export const MILLISECONDS_IN_DAY: number = 1000 * 60 * 60 * 24;

/**
 * Delay for displaying thanks message, in milliseconds.
 *
 * @type {number}
 */
export const THX_DELAY: number = 2 * 1000; // 2 seconds

/**
 * Check if a question is expired based on the last answered date and question frequency.
 * @param {string} lastAnsweredDate - The timestamp when the question was last answered.
 * @param {number} questionFrequency - The frequency (in days) indicating how long the question is considered valid.
 * @returns {boolean} - True if the question is expired, false otherwise.
 */
export const isQuestionExpired = (lastAnsweredDate: string, questionFrequency: number): boolean => {
  const now = new Date().getTime();
  const daysSinceLastAnswered = (now - new Date(lastAnsweredDate).getTime()) / MILLISECONDS_IN_DAY;
  return daysSinceLastAnswered > questionFrequency;
};

/**
 * Helper function to get the corresponding question from npsData based on questionId and expiration criteria.
 * @param {string} questionId - The UUID v4 of the question.
 * @param {string} createdAt - The timestamp when the answer was created.
 * @returns {SurveyQuestionType | null} - The corresponding question from npsData or null if not found.
 */
export const getCorrespondingQuestion = (questionId: string, createdAt: string): SurveyQuestionType | null => {
  const correspondingQuestion = npsData.find((item) => item.id === questionId);

  if (!correspondingQuestion) {
    return null;
  }

  switch (questionId) {
    case DESCRIBES_QUESTION_ID:
      return correspondingQuestion;
    case RECOMMEND_QUESTION_ID:
    case SURVEYS_QUESTION_ID:
      // TODO: change for to production COOLDOWN_INTERVAL
      if (!isQuestionExpired(createdAt, COOLDOWN_INTERVAL)) {
        return correspondingQuestion;
      }
      break;
    // Add more cases if add more questions
    default:
      // Handle other questionId values if necessary
      break;
  }

  return null;
};
