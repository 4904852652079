import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

const ToggleSwitchStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;

  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 36px;
  height: 20px;
  background: ${COLOR_MAP.controls.strokeNormal};
  border-radius: 10px;
  padding: 2px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${COLOR_MAP.controls.clickableNormal};

    &:before {
      transform: translate(100%, -50%);
    }
  }
`;

export { Input, Label, Switch, ToggleSwitchStyled };
