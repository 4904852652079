/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { deleteToast } from '../../redux/reducers/toast-reducer';
import { COLOR_MAP } from '../colorsMap';
import { Text } from '../simple/Text';
import { notificationColors, notificationIcons, NotificationType } from '../types';
import { darkenHex } from '../utils';

const ToastContainer = styled.div`
  display: flex;
`;

const WrapperError = styled.div<{ type: NotificationType }>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;

  background-color: ${({ type }) => notificationColors[type]};
  border-radius: 4px 0 0 4px;

  & path {
    stroke: ${COLOR_MAP.controls.textAndIcon};
  }
`;

const WrapperCloseBtn = styled.div<{ type: NotificationType }>`
  position: relative;

  display: flex;
  align-items: center;

  padding: 7px;

  background-color: ${({ type }) => `${darkenHex(notificationColors[type], 0.1)}`};
  border-radius: 0 4px 4px 0;

  &:hover {
    background-color: ${({ type }) => `${darkenHex(notificationColors[type], 0.2)}`};

    cursor: pointer;
  }

  &:active {
    background-color: ${({ type }) => `${darkenHex(notificationColors[type], 0.2)}`};

    cursor: pointer;
  }
`;

const Cross = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

type Props = {
  text: string;
  id: string;
  type?: NotificationType;
};

export const Toast = ({ text, id, type = NotificationType.Default }: Props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);

  const closeHandler = () => {
    dispatch(deleteToast({ id }));
    setIsOpen(false);
  };

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      dispatch(deleteToast({ id }));
    }, 7000);
    return () => clearTimeout(idTimeOut);
  }, []);

  const Icon = notificationIcons[type];

  return (
    <>
      {isOpen && (
        <ToastContainer>
          <WrapperError type={type}>
            {Icon && <Icon />}
            <Text style={{ whiteSpace: 'pre-line' }} colorText={COLOR_MAP.text.white}>
              {text}
            </Text>
          </WrapperError>
          <WrapperCloseBtn type={type} onClick={closeHandler}>
            <Cross />
          </WrapperCloseBtn>
        </ToastContainer>
      )}
    </>
  );
};
