import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetSurveysQuery } from '../../../../api/auth/hooks';
import { PostSurveyAnswersType, SurveyQuestionType } from '../../../../api/product/type';
import { useUpdateSurveyAnswersMutate } from '../../../../api/surveyAnswers/hooks/useUpdateSurveyAnswersMutate';
import { noop } from '../../../../componentUI/utils';
import { useStyledModal } from '../../../../hooks';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { NpsContent } from '../NpsContent';
import { NpsThanks } from '../NpsContent/NpsThanks';
import { npsData, SURVEY_ID } from '../NpsContent/npsData';
import { getCorrespondingQuestion, THX_DELAY } from './helpers';

type NpsModalProps = {
  isNewUserAccount: boolean | undefined;
};

export const NpsModal = ({ isNewUserAccount }: NpsModalProps) => {
  const [Modal, toggleModal] = useStyledModal();
  const dispatch = useDispatch();

  const { data: surveysData } = useGetSurveysQuery();
  const { mutateAsync: UpdateSurveyAnswerAsync } = useUpdateSurveyAnswersMutate();

  const [questions, setQuestions] = useState<SurveyQuestionType[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isShowThanks, setIsShowThanks] = useState(false);

  useEffect(() => {
    if (surveysData) {
      const isOldUser = !isNewUserAccount || false;

      // Map each survey to an object containing surveyId and questions
      // right now we have only one survey
      const answeredQuestions = surveysData
        // Filter surveys with non-empty answers array
        .filter((survey) => survey.answers.length > 0)
        .map((survey) => {
          const { surveyId } = survey;

          // Reduce the answers array to keep only the most recent answer for each question
          const answers = survey.answers.reduce((acc: { [key: string]: string }, answer) => {
            // Check if current questionId already exists in accumulator (acc) or if the current answer is more recent
            if (!acc[answer.questionId] || new Date(answer.createdAt) > new Date(acc[answer.questionId])) {
              // If current answer is more recent or question hasn't been answered before,
              // update accumulator with new answer
              acc[answer.questionId] = answer.createdAt;
            }
            return acc;
          }, {});

          // Map the filtered answers to an array of question objects containing questionId and createdAt
          return {
            surveyId,
            questions: Object.keys(answers).map((questionId) => ({
              questionId,
              createdAt: answers[questionId],
            })),
          };
        });

      const userHasAnsweredNPS = answeredQuestions.length > 0;
      const oldUserQuestions: SetStateAction<SurveyQuestionType[]> = npsData;

      if (!userHasAnsweredNPS) {
        if (isOldUser) {
          // user don't have answers
          // old user without answers, show all
          setQuestions([...npsData]);
          // open modal
          toggleModal();
        }
      }

      if (userHasAnsweredNPS) {
        // user have some answers
        if (isOldUser) {
          // if user new and have answer - this is only first survey, so we don't show anything
          answeredQuestions.forEach((answeredQuestion) => {
            const { surveyId, questions } = answeredQuestion;

            // TODO: change this logic if we have more than one survey
            if (surveyId === SURVEY_ID) {
              questions.forEach((answeredQuestion) => {
                const { questionId, createdAt } = answeredQuestion;

                const correspondingQuestion = getCorrespondingQuestion(questionId, createdAt);

                // If correspondingQuestion exists and is found in oldUserQuestions, remove it
                if (correspondingQuestion) {
                  const index = oldUserQuestions.findIndex((item) => item.id === correspondingQuestion.id);
                  if (index !== -1) {
                    oldUserQuestions.splice(index, 1);
                  }
                }
              });
            }
          });

          setQuestions(oldUserQuestions);
          // open modal
          if (questions.length > 0) {
            toggleModal();
          }
        }
      }
    }
  }, [surveysData, isNewUserAccount]);

  // NPS modal handler
  /**
   * Handle the response from the NPS survey and update the state accordingly.
   * @param {PostSurveyAnswersType} answer - The answer submitted by the user for the NPS question.
   */
  const handleNPSResponse = async (answer: PostSurveyAnswersType) => {
    try {
      await UpdateSurveyAnswerAsync(answer);
      // Show the next question if available
      setCurrentQuestionIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex < questions.length) {
          return nextIndex;
        }

        setIsShowThanks(true);

        // Close the modal after 5 seconds
        setTimeout(() => {
          toggleModal();
          setIsShowThanks(false); // Reset the state for the next survey
        }, THX_DELAY);

        return prevIndex; // No more questions, keep the current index
      });
    } catch (error) {
      dispatch(
        addToast({
          text: 'Server error. Please try again.',
        }),
      );
    }
  };

  /**
   * Handle the modal closure by updating the local storage timestamp.
   * This function is triggered when the NPS modal is closed.
   */
  const onCloseModal = () => {
    // Update local storage timestamp when the modal is closed
    localStorage.setItem('npsClosedTimestamp', new Date().getTime().toString());
  };

  return (
    <>
      {questions.length > 0 && (
        // need for save to localstorage info only if user close survey, not NpsThanks
        <Modal onClose={!isShowThanks ? onCloseModal : noop} isNps>
          {isShowThanks ? (
            <NpsThanks />
          ) : (
            <NpsContent onSubmit={handleNPSResponse} question={questions[currentQuestionIndex]} />
          )}
        </Modal>
      )}
    </>
  );
};
