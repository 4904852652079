import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';

// https://utopia.fyi/clamp/calculator -вычисление clamp

const ContainerPictureStyled = styled.div`
  position: relative;

  height: 102px;
  width: 102px;

  display: flex;
  justify-content: center;
  align-items: center;

  object-fit: cover;

  border-radius: 6px;

  ${truthfulAdaptive.maxWidth.desktopSNew} {
    width: 128px;
    height: 128px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    width: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
    height: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
  }
`;

const WrapperSkeletonStyled = styled.div`
  height: 102px;
  width: 102px;

  ${truthfulAdaptive.maxWidth.desktopSNew} {
    width: 128px;
    height: 128px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    width: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
    height: clamp(5rem, -1.5714rem + 32.8571vw, 10.75rem);
  }
`;

const stylesForButtons: CSSProperties = {
  position: 'absolute',
  right: '-6px',
  top: '-6px',

  padding: '4px',

  backgroundColor: COLOR_MAP.background.white,
  boxShadow: '0px 4px 9px rgba(150, 112, 38, 0.05), 0px 5px 14px rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
};

const stylesForImg: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '6px',
};

export { ContainerPictureStyled, stylesForButtons, stylesForImg, WrapperSkeletonStyled };
