import React, { useEffect, useState } from 'react';
import { ButtonV2 } from '../../../../componentUI/simple/Button';
import { PostSurveyAnswersType, SurveyQuestionType } from '../../../../api/product/type';
import { RadioChipsV2, SquareRadioChipsV2 } from '../../../../componentUI/simple/Radio';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { TextareaV2 } from '../../../../componentUI/simple/TextareaV2';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { SURVEY_ID } from './npsData';
import { LabelWrapper, ModalContent, RadioContainer, RadioContainerRow, RadioWrapperRow } from './styles';
import { createRandomUUID } from '../../../../helpers/createRandomUUID';

type NPSContentProps = {
  onSubmit: (response: PostSurveyAnswersType) => void;
  question: SurveyQuestionType;
};

export const NpsContent = ({ onSubmit, question }: NPSContentProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [otherText, setOtherText] = useState('');
  const [hasErrorTextArea, setHasErrorTextArea] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // Update conditions whenever selectedOption, otherText, or hasErrorTextArea changes
  useEffect(() => {
    const newIsDisabled =
      !selectedOption || // Disable if selectedOption is falsy
      (selectedOption === 'Other' && otherText.trim() === '') || // Disable if "Other" selected and otherText is empty
      hasErrorTextArea; // Disable if there is an error in the textarea

    setIsDisabled(newIsDisabled);
  }, [selectedOption, otherText, hasErrorTextArea]);

  const handleTextareaErrorChange = (isError: boolean) => {
    setHasErrorTextArea(isError);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleTextareaChange = (value: string) => {
    setOtherText(value);
  };

  const handleSubmit = () => {
    let answerText = selectedOption;

    // If "Other" is selected and there is text in the textarea, use it as the answer
    if (selectedOption === 'Other' && otherText.trim() !== '') {
      answerText = otherText.trim();
    }

    // Submit the response to the server with first survey 21c7d46a-99e3-4d97-bfcf-9100dcda259f
    // TODO: Change surveyId logic if have more than one survey
    onSubmit({
      surveyId: SURVEY_ID,
      answers: [{ questionId: question.id, answerText }],
    });
  };

  return (
    <>
      <ModalContent key={question.id}>
        <Title size={TITLE_SIZE.h6}>{question.text}</Title>
        {question.answers.length > 0 ? (
          // Use existing answers
          <RadioContainer>
            {question.answers.map((answer) => (
              <RadioChipsV2
                key={createRandomUUID()}
                checkedValue={selectedOption}
                label={answer}
                value={answer}
                name={`question_${question.id}`}
                onChange={handleOptionChange}
                withBorder={false}
              />
            ))}
            {selectedOption === 'Other' && (
              <TextareaV2
                onChange={handleTextareaChange}
                onErrorChange={handleTextareaErrorChange}
                placeholder="Provide additional information"
                maxLength={300}
                style={{ maxWidth: '400px' }}
              />
            )}
          </RadioContainer>
        ) : (
          // Show 10 SquareRadioChipsV2 with labels from 1 to 10 in a row
          <RadioContainerRow>
            <RadioWrapperRow>
              {Array.from({ length: 10 }).map((_, index) => (
                <SquareRadioChipsV2
                  key={createRandomUUID()}
                  checkedValue={selectedOption}
                  label={(index + 1).toString()}
                  value={(index + 1).toString()}
                  name={`question_${question.id}`}
                  onChange={handleOptionChange}
                />
              ))}
            </RadioWrapperRow>
            <LabelWrapper>
              <Text size={TEXT_SIZE.sub}>Not at all likely </Text>
              <Text size={TEXT_SIZE.sub}>Extremely likely</Text>
            </LabelWrapper>
          </RadioContainerRow>
        )}
      </ModalContent>
      <ButtonV2 onClick={handleSubmit} disabled={isDisabled}>
        SEND
      </ButtonV2>
    </>
  );
};
