import React from 'react';

import { useMatchMedia } from '../../../../hooks';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../../componentUI/skeleton';

import { BalanceMainSkeleton } from '../BalanceMainSkeleton';

import {
  AccountSettingsSkeletonStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerBlockTwoStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerStyled,
} from './styled';

export const BalanceWrapperMainSkeleton = () => {
  const { isDesktop } = useMatchMedia();

  return (
    <AccountSettingsSkeletonStyled>
      {isDesktop && (
        <ColumnMenuStyled>
          <HeaderColumnMenuStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </HeaderColumnMenuStyled>

          <MainColumnMenuStyled>
            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="221px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="166px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

            <MainColumnMenuBlockOneStyled>
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </MainColumnMenuBlockOneStyled>

            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </MainColumnMenuStyled>

          <FooterColumnMenuStyled>
            <HeaderColumnMenuBlockOneStyled>
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            </HeaderColumnMenuBlockOneStyled>

            <HeaderColumnMenuBlockTwoStyled>
              <SkeletonBasic width="206px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="24px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </HeaderColumnMenuBlockTwoStyled>

            <SkeletonBasic
              width="160px"
              height="16px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '26px' }}
            />

            <SkeletonBasic
              width="250px"
              height="10px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '40px' }}
            />
          </FooterColumnMenuStyled>
        </ColumnMenuStyled>
      )}

      {!isDesktop && (
        <ColumnMenuTopStyled>
          <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

          <ColumnMenuTopBlockOneStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </ColumnMenuTopBlockOneStyled>
        </ColumnMenuTopStyled>
      )}

      <MainContainerStyled>
        <HeaderMainContainerStyled>
          <HeaderMainContainerBlockOneStyled>
            <SkeletonBasic width="400px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />

            {isDesktop && (
              <HeaderMainContainerBlockTwoStyled>
                <SkeletonBasic width="190px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="38px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </HeaderMainContainerBlockTwoStyled>
            )}
          </HeaderMainContainerBlockOneStyled>
        </HeaderMainContainerStyled>

        <BalanceMainSkeleton />
      </MainContainerStyled>
    </AccountSettingsSkeletonStyled>
  );
};
