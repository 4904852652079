import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../../../componentUI/complex/InputWithError';
import { Asterisk } from '../../../../../../componentUI/simple/Asterisk';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';
import { TemplateNameStyled } from './styled';
import { Props } from './types';

export const TemplateName = ({ templateName, setTemplateName, templateNameError, setTemplateNameError }: Props) => {
  const { t } = useTranslation();

  const changeTemplateName = (name: string) => {
    setTemplateNameError(name ? '' : t('customCertificate.manageTemplates.templateName.error'));
    setTemplateName(name);
  };

  return (
    <TemplateNameStyled>
      <Text weight={TEXT_WEIGHT.bold} size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
        {t('customCertificate.manageTemplates.templateName.label')}
        <Asterisk />
      </Text>

      <InputWithError
        value={templateName}
        onChange={(event: string) => {
          changeTemplateName(event);
        }}
        onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
          changeTemplateName(event.currentTarget.value);
        }}
        placeholder={t('customCertificate.manageTemplates.templateName.placeholder')}
        errorMessage={templateNameError}
        type="text"
      />
    </TemplateNameStyled>
  );
};
