/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';

import { SkeletonBasic } from '../../../../componentUI/skeleton';

import { useMatchMedia } from '../../../../hooks';

import { ChartStyled, OrderStatisticsChartStyled } from './styled';
import { OrderStatisticsChartType } from './type';

// context.arc(x,y,r,sAngle,eAngle,counterclockwise);

// На счёт x,y — начальная точка, конечная точка, r - радиус,
// необязательная опция counterclockwise — говорит направление в котором рисовать окружность

// sAngle и eAngle это начальная и конечная точка на окружности
// в радианах, более понятно что такое радианы объяснит гифка:

// Чтобы перевести проценты в радианы нужно использовать формулу:
// $radian=2*π*procent/100

export const OrderStatisticsChart = ({
  text,
  percentage,
  chartColor,
  backgroundChartColor,
  isLoadingStatistics,
}: OrderStatisticsChartType) => {
  const { isMobile } = useMatchMedia();
  const pieChart = useRef<HTMLCanvasElement>(null);

  // размер диаграмм по макету
  const referenceSize = isMobile ? 120 : 140;
  // толщина круга
  const borderThickness = isMobile ? 18 : 20;

  useEffect(() => {
    let ctx = null;

    if (pieChart.current) {
      // создаем рабочую область для диаграммы
      pieChart.current.width = referenceSize;
      pieChart.current.height = referenceSize;

      ctx = pieChart.current.getContext('2d');
    }

    if (ctx) {
      // пересчитываем проценты в радианы для корректной отрисовки процента заполнения круга
      const radian = (2 * Math.PI * percentage) / 100;

      // рисуем круг для фона, значения и закономерность подобраны исходя из макета
      ctx.arc(
        (referenceSize - borderThickness) / 2 + borderThickness / 2,
        (referenceSize - borderThickness) / 2 + borderThickness / 2,
        (referenceSize - borderThickness) / 2,
        0,
        2 * Math.PI,
        false,
      );
      ctx.lineWidth = borderThickness;
      ctx.strokeStyle = backgroundChartColor;
      ctx.stroke();
      ctx.beginPath();

      // рисуем круг с процентами, значения и закономерность подобраны исходя из макета
      ctx.arc(
        (referenceSize - borderThickness) / 2 + borderThickness / 2,
        (referenceSize - borderThickness) / 2 + borderThickness / 2,
        (referenceSize - borderThickness) / 2,
        1.5 * Math.PI,
        radian + 1.5 * Math.PI,
        false,
      );
      ctx.strokeStyle = chartColor;
      ctx.stroke();
    }
  }, [pieChart, referenceSize]);

  return (
    <OrderStatisticsChartStyled>
      {isLoadingStatistics && (
        <SkeletonBasic
          width={`${referenceSize}px`}
          height={`${referenceSize}px`}
          bgColor={COLOR_MAP.controls.skeleton.content2}
        />
      )}

      {!isLoadingStatistics && (
        <>
          <ChartStyled>
            <canvas width="100%" height="100%" ref={pieChart} />

            <Title
              style={{ position: 'absolute' }}
              colorText={COLOR_MAP.text.darkGrey}
              size={TITLE_SIZE.h4}
              tag={TAG_NAME.h4}
              weight={TITLE_WEIGHT.normal}
            >
              {percentage} %
            </Title>
          </ChartStyled>

          <Title
            colorText={COLOR_MAP.text.darkGrey}
            size={TITLE_SIZE.h6}
            tag={TAG_NAME.h6}
            style={{ whiteSpace: 'nowrap' }}
          >
            {text}
          </Title>
        </>
      )}
    </OrderStatisticsChartStyled>
  );
};
