import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';

const WrapperButtonStyled = styled.div`
  margin-top: 4px;
  width: 300px;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export { stylesForButtons, WrapperButtonStyled };
