import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { ResetPasswordRequestType } from '../authTypes';
import { resetPassword } from '../index';
import { ErrorType } from './types';

export const useResetPasswordMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.resetPasswordMutate],
    mutationFn: async (resetPasswordData: ResetPasswordRequestType) => {
      const { data } = await resetPassword(resetPasswordData);

      return data;
    },

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
