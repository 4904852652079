import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const SummaryStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContainerInfoStyled = styled.div`
  display: flex;

  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(150, 112, 38, 0.05);

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
  }
`;

const WrapperStatisticsStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const ChartsInfoStyled = styled.div`
  padding: 20px 16px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StatisticsFulfilledRequestsStyled = styled.div`
  padding: 20px 16px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-left: 1px solid ${COLOR_MAP.text.lightGrey};
  border-bottom: 1px solid ${COLOR_MAP.text.lightGrey};

  ${truthfulAdaptive.maxWidth.mobile} {
    border-left: none;
    border-top: 1px solid ${COLOR_MAP.text.lightGrey};
  }
`;

const WrapperItemFulfilledRequestsStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatisticsRequestStatusesStyled = styled.div`
  padding: 20px 16px;

  border-left: 1px solid ${COLOR_MAP.text.lightGrey};

  ${truthfulAdaptive.maxWidth.mobile} {
    border-left: none;
  }
`;

export {
  ChartsInfoStyled,
  ContainerInfoStyled,
  StatisticsFulfilledRequestsStyled,
  StatisticsRequestStatusesStyled,
  SummaryStyled,
  WrapperItemFulfilledRequestsStyled,
  WrapperStatisticsStyled,
};
