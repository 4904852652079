import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { truthfulAdaptive } from '../../componentUI/screen';

const Container = styled.div<{ isAdaptive: boolean }>`
  display: flex;
  align-items: center;

  ${truthfulAdaptive.minWidth.tablet} {
    width: ${({ isAdaptive }) => (isAdaptive ? '83px' : 'auto')};
  }
`;

type Props = {
  id: string;
  RowElement: JSX.Element;

  isAdaptive?: boolean;
};

export const AddCertFromCompletedAuth = ({ id, RowElement, isAdaptive = true }: Props) => {
  const navigate = useNavigate();

  const navigateToCardProduct = () => navigate(`../card/${id}`, { state: { isOpenMenuCustomCertificate: true } });

  return (
    <Container
      isAdaptive={isAdaptive}
      onClick={(event) => {
        event.stopPropagation();
        return navigateToCardProduct();
      }}
    >
      {RowElement}
    </Container>
  );
};
