export const getImageDimensions = async (file: File) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();

  const { width } = img;
  const { height } = img;

  URL.revokeObjectURL(img.src);

  return {
    width,
    height,
  };
};
