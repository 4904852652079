import { useLocation, useNavigate } from 'react-router-dom';

export const useGoBack = (isError: boolean = false) => {
  const navigate = useNavigate();
  const location = useLocation();

  return () => {
    if (window.history.length > 1 && location.state?.from) {
      navigate(location.state.from, { replace: isError });
    } else {
      navigate('/', { replace: isError });
    }
  };
};
