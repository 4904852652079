import React, { useEffect, useRef } from 'react';
import { useMatchMedia, useStyledModal } from '../../../../hooks';
import { BrandClassification } from '../../../BrandClassification';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { RightArrow } from '../../../../common/icons/SvgInline/SvgIcons_7';
import { useOnScreen } from '../../hooks/useOnScreen';
import { IconWrapper, Separator, StyledButton, StyledLinkClassic, StyledLinkRouter, TextWrapper } from './styled';
import { HelpCardButtonType } from './type';

export const HelpCardButton = ({
  id,
  isLocal,
  isModal,
  bgColor,
  Icon,
  text,
  subText,
  url,
  getIsLightenLeft,
  getIsLightenRight,
}: HelpCardButtonType) => {
  const { isTablet } = useMatchMedia();
  const observeRef = useRef(null);
  const [isVisible, fieldView] = useOnScreen(observeRef);
  const [BrandClassificationModal, brandClassificationToggleModal] = useStyledModal();

  useEffect(() => {
    if (id === 'howItWorks') {
      getIsLightenLeft(!isVisible);
    }

    if (id === 'brandCoverage') {
      getIsLightenRight(!(isVisible && fieldView >= 1));
    }
  }, [isVisible, fieldView, id, getIsLightenLeft, getIsLightenRight]);

  const content = (
    <>
      <IconWrapper>
        <Icon width={isTablet ? '76' : '64'} height={isTablet ? '72' : '60'} />
      </IconWrapper>

      {!isTablet && <Separator />}

      <TextWrapper>
        {subText && (
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.white}>
            {subText}
          </Text>
        )}

        <Text
          style={{ textTransform: 'uppercase' }}
          size={TEXT_SIZE.regular}
          colorText={COLOR_MAP.text.white}
          weight={TEXT_WEIGHT.bold}
        >
          {text}
        </Text>
      </TextWrapper>

      {!isTablet && <RightArrow />}
    </>
  );

  if (isLocal) {
    return (
      <StyledLinkRouter to={url} bgColor={bgColor} ref={observeRef}>
        {content}
      </StyledLinkRouter>
    );
  }

  if (isModal) {
    return (
      <>
        <StyledButton onClick={brandClassificationToggleModal} bgColor={bgColor} refBtn={observeRef}>
          {content}
        </StyledButton>
        <BrandClassificationModal isBigHeight onClose={brandClassificationToggleModal}>
          <BrandClassification onClose={brandClassificationToggleModal} />
        </BrandClassificationModal>
      </>
    );
  }

  return (
    <StyledLinkClassic href={url} target="_blank" rel="noopener noreferrer" bgColor={bgColor} ref={observeRef}>
      {content}
    </StyledLinkClassic>
  );
};
