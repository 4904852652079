import React, { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import { TAG_NAME } from '../../constants';
import { SIZE_STYLE, TEXT_SIZE, TEXT_WEIGHT } from './constant';
import { COLOR_MAP } from '../../colorsMap';

type PropsText = HTMLAttributes<HTMLElement> & {
  colorText: string;
  weight: string;
  size: keyof typeof TEXT_SIZE;
  tag?: keyof JSX.IntrinsicElements;
  htmlFor?: string;
  refText?: RefObject<HTMLElement> | null | undefined;
  margins: [number, number, number, number] | [number, number, number] | [number, number] | [number];
};

const TitleComponent = styled.p<PropsText>`
  font-family: 'Open Sans', sans-serif;
  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};
  ${({ weight }) => `font-weight: ${weight}`};

  margin: ${({ margins }) => margins.map((margin) => `${margin}px `).join('')};
`;

export const Text = ({
  size = TEXT_SIZE.regular,
  colorText = COLOR_MAP.text.black,
  weight = TEXT_WEIGHT.normal,
  tag = TAG_NAME.p,
  margins = [0],
  refText,
  children,
  ...otherProps
}: Partial<PropsText>) => {
  return (
    <TitleComponent
      ref={refText}
      as={tag}
      colorText={colorText}
      size={size}
      weight={weight}
      margins={margins}
      {...otherProps}
    >
      {children}
    </TitleComponent>
  );
};
