import { NameStatus } from '../products/type';

export type productTypeAngles = {
  iconUrl: string | null;
  publicName: string | null;
  angleId: string;
  forCertificate: true;
  id: string;
  necessity: number;
  orderIndex: number | null;
  productTypeId: string;
  zendeskUrl: string | null;
};

export type BrandType = {
  brandTypeId: string;
  categoryId: string;
  clickupId: string | null;
  id: string;
  isHidden: null;
  legitCheckId: string;
  name: string;
  publicName: string;
};

export type BrandTypesResponse = {
  entities: ReadonlyArray<BrandType>;
  itemCount: number;
  limit: number;
  page: number;
};

export type GetStatisticsResponse = {
  submitteditems: number;
  canceled: number;
  fake: number;
  authentic: number;
  underReview: number;
  photosNeed: number;
  completed: number;
  drafts: number;
};

export type UploadRequestPhotosType = {
  angleId: string;
  fileId?: string;
  isUnableProvide: boolean;
};

export type UploadRequestBody = {
  productId: string;
  photos: Array<UploadRequestPhotosType>;
};

export type GetCheckStatusesResponse = {
  LEGIT: string;
  FAKE: string;
  CANCELED: string;
};

export type addCertificateOfCardProductType = {
  productId: string;
  userCertificateTemplateId: string | null;
};

export type addCertificateOfCardProductResponseType = {
  result: boolean;
};

export type AuthenticationPriceRequestBody = {
  answerTimeId: string | null;
  brandId: string | null;
  productTypeId: string | null;
  userCertificateTemplateId: string | null;
  isFinancialGuaranteeNeeded: boolean | null;
};

export type AuthenticationPriceResponse = {
  authenticationPrice: number;
  certificatePrice: number;
  financialGuaranteePrice?: number;
  total: number;
};

export type ImageResponseType = {
  angleId: string | null;
  awsKey: string;
  createdAt: string;
  deletedAt: string;
  extension: string;
  feature: string;
  id: string;
  isAdditional: boolean;
  isSelected: boolean;
  jpegUrl: string | null;
  modelId: string;
  name: string;
  path: string;
  productId: string | null;
  sentAt: string | null;
  userId: string | null;
};

export type ModelListEntity = Array<{
  brandId: string;
  displayName: string;
  id: string;
  image: ImageResponseType;
  productTypeId: string;
}>;

export enum ResultStatusName {
  OPEN = 'OPEN',
  UPDATED = 'UPDATED',
  ERROR = 'ERROR',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  AWAIT_CERTIFICATE = 'AWAIT_CERTIFICATE',
  DRAFT = 'DRAFT',
}

export enum CheckStatus {
  FAKE = 'FAKE',
  LEGIT = 'LEGIT',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
}

export enum ResultStatusId {
  OPEN_ID = '4faba4f7-dd01-45e8-855d-e962a66ec0ab',
  UPDATE_NEEDED_ID = '6db15f2f-4143-4bd4-9b88-969842f8b48e',
  UPDATED_ID = 'af0356e9-f69f-4caf-8a54-9b8b8d99fdd8',
  ERROR_ID = '81c359a4-a1e9-445b-8050-07198f92e6e8',
  COMPLETED_ID = '3eaecf08-76ee-4f82-9104-5ba88e837dfd',
  READY_ID = '5129c3e3-f0a6-4472-9c97-f59a4013a7c3',
  AWAIT_CERTIFICATE_ID = '07969a42-1c15-4640-9152-2b9fff272ec9',
  DOUBLE_CHECK_ID = '2cdb5ff8-2556-4a9a-9f6e-03dfe4bec89a',
  AWAIT_QR_LABEL_ID = '71e2b6bb-0c9c-4c06-883d-15ab64447f91',
  DRAFT_ID = '4cef58ca-3c4a-424e-a149-004bc71b4a4b',
}

export enum SortProduct {
  resultStatusUp = 'resultStatus:DESC',
  resultStatusDown = 'resultStatus:ASC',
  createdAtUp = 'createdAt:DESC',
  createdAtDown = 'createdAt:ASC',
  answerTimeUp = 'answerTime:DESC',
  answerTimeDown = 'answerTime:ASC',
  checkStatusUp = 'checkStatus:DESC',
  checkStatusDown = 'checkStatus:ASC',
}

export type AngleTypes = {
  id: string;
  name: string;
  publicName: string | null;
  clickupId: null | string;
  __entity: string;
  iconUrl: null | string;
  orderIndex: number | null;
};

export type AngelsTypes = {
  clickupId: string | null;
  id: string;
  name: string;
  publicName: string;
  clickupName: string;
  iconUrl: string | null;
  idA4000: number | null;
  legitCheckId: string;
  orderIndex: number | null;
  __entity: string;
};

export type ProductAngleTypes = {
  id: string;
  name: string;
  publicName: string | null;
  clickupId: null | string;
  clickupName: string;
  legitCheckId: string;
  iconUrl: null | string;
  orderIndex: number | null;
  comment: string | null;
  idA4000: number;
  productTypeAngles: Array<productTypeAngles>;
  imageSamples: Array<string> | null;
  __entity: string;
};

export type ProductReasonsType = {
  angle: ProductAngleTypes;
  angleId: string;
  comment: string | null;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  isUnableProvide: boolean;
  isUploaded: boolean;
  problems: Array<string>;
  productId: string;
  title: string;
  updatedAt: string | null;
};

export type AnswerTimeTypes = {
  createdAt: string;
  default: boolean;
  id: string;
  name: string;
  shortName: string;
  updatedAt: null;
  value: string;
  __entity: string;
};

export type ResultStatusType = {
  id: string;
  name?: ResultStatusName;
  publicName?: string;
  clickupName?: string;
};

export type FileType = {
  id: string;
  productId: string | null;
  userId: string | null;
  awsKey: string;
  path: string;
  name: string;
  extension: string;
  feature: string;
  angleId: string;
  isSelected: boolean | null;
  jpegUrl: string | null;
  isAdditional: boolean | null;
  createdAt: string;
  angle: AngelsTypes | null;
  problems?: Array<string>;
  comment?: string | null;
  resizedFilePath?: string | null;
};

export type BundleTypes = {
  clickupId: string;
  id: string;
  name: string;
  publicName: string;
  __entity: string;
};

export type AnglesType = {
  id: string;
  name: string;
  necessity: number;
  iconUrl: string;
  orderIndex: number;
  publicName: string;
  zendeskUrl: string;
  imageSamples: Array<string>;
};

export type userCreditElement = {
  balanceAfter: number;
  comment: null;
  createdAt: string;
  description: string;
  discountPrice: null;
  id: string;
  invoiceId: null;
  price: null;
  productId: string;
  promoPackageId: null;
  promocodeDiscount: null;
  subscriptionDiscount: null;
  subscriptionId: null;
  total: null;
  totalDiscount: null;
  totalDiscountedPrice: null;
  totalPromocodeDiscount: null;
  totalSubscriptionDiscount: null;
  type: {
    id: number;
    name: number;
    displayName: number;
  };
  typeId: string;
  userId: string;
  volume: number;
};

type CertificateTemplateType = {
  certificateOrientation: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  orderIndex: number | null;
  path: string | null;
  updatedAt: string;
};

type LogoType = {
  angleId: string | null;
  awsKey: string;
  createdAt: string;
  deletedAt: string | null;
  extension: string;
  feature: string;
  id: string;
  isAdditional: false;
  isSelected: string | null;
  jpegUrl: string | null;
  modelId: string | null;
  name: string;
  path: string;
  productId: string | null;
  sentAt: string | null;
  userId: string;
};

type UserCertificateTemplateType = {
  certificateTemplate: CertificateTemplateType;
  certificateTemplateId: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  isDefault: boolean;
  logo: LogoType;
  logoId: string;
  name: string;
  qrCodeNeeded: boolean;
  updatedAt: string | null;
  userId: string;
};

export type FakeReasonAngleType = {
  clickupId: string;
  clickupName: string;
  iconUrl: string;
  id: string;
  idA4000: number;
  legitCheckId: string;
  name: string;
  orderIndex: number | null;
  publicName: string;
};

// Тип неполный - бэк проапдейтит типы потом взять из сваггера
export type GetProductResponseType = {
  brandId: string;
  productTypeId: string;
  id: string;
  answerTimeId: string | null;
  autoincrement: number;
  certificateAvailable: boolean;
  certificateNeeded: boolean;
  checkStatus: CheckStatus | null;
  clickupId: string;
  clientProductNumber: number;
  comment: null;
  isPhotosUploaded: null;
  itemLabelAvailable: boolean;
  itemLabelNeeded: boolean;
  publicId: string;
  hashPhoto: string;
  name: null;
  orderId: string;
  productSubType: string | null;
  reasons: Array<ProductReasonsType> | undefined;
  modelName: string;
  supplier: string;
  systemStatus: string;
  zendeskTicketId: null;
  zendeskTicketUrl: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  dueDate: string;
  photosRequestedAt: null;
  photosRequestedReminderAt: null;
  firstPhotosRequestedAt: null;
  completedAt: null;
  answerTimeOld: null;
  answerTime: AnswerTimeTypes;
  brand: BrandType;
  resultStatus: {
    id: string;
    name: ResultStatusName;
  };
  files: Array<FileType>;
  brandType: BundleTypes;
  orderNumber: string;
  sku?: string | null;
  productType: {
    id: string;
    name: string;
    publicName: string;
    clickupName: string;
    isSendA4000: boolean;
    legitCheckName: string;
    productTypeAngles: Array<AnglesType>;
  };
  additionalNotes: string | null;
  isDelayed: boolean | null;
  isShared: boolean | null;
  refundedAt: string | null;
  userCredits: Array<userCreditElement>;
  unableToVerify: boolean;
  isCertificateUpdated: boolean;
  userCertificateTemplate: UserCertificateTemplateType | null;
  userCertificateTemplateId: string | null;
  fakeReasonAngleId: string | null;
  fakeReasonAngle: FakeReasonAngleType | null;
  isFinancialGuaranteeActive: boolean;
  isFinancialGuaranteePaid: boolean;
  financialGuaranteeDueDate: string | null;
  doubleCheckStartedAt: string | null;
  isDoubleCheck?: boolean;
  modelId: string | null;
};

export type GetProductByIdAnglesType = {
  id: string;
  publicName: string;
  orderIndex: number;
  photos: Array<{
    id: string;
    url: string;
  }>;
  reason: {
    title: string;
    comment: string | null;
    problems: Array<string> | null;
    imageSamples: Array<string>;
    photoUrl: string | null;
  };
  iconUrl: string | null;
};

export type HistoryLinePoint = {
  date: string;
  description: string;
};

export type GetProductLineByIdResponseType = HistoryLinePoint[];

export type GetProductByIdOutcomeReasonType = {
  anglePublicName: string;
  title: string;
};

export type GetProductByIdResponseType = {
  id: string;
  orderNumber: string;
  status: NameStatus | null;
  isDoubleCheck: boolean;
  checkStatus: CheckStatus | null;
  isDelayed: boolean | null;
  answerTime: string;
  brandType: string;
  brand: string;
  productType: string;
  refundedAt: string | null;
  modelName: string;
  sku: string;
  additionalNotes: string;
  systemStatus: string;
  certificateAvailable: boolean;
  publicId: string;
  isCertificateUpdated: boolean | null;
  certificateUrl: string;
  certificateCreatedAt: string;
  itemLabelUrl: string | null;
  createdAt: string;
  brandId: string;
  productTypeId: string;
  productSubType: string;
  isFinancialGuaranteePaid: boolean;
  isFinancialGuaranteeActive: boolean;
  financialGuaranteeDueDate: string;
  creditsSpent: number;
  productTypeIdA4000: number;
  brandIdA4000: number | null;
  angles: GetProductByIdAnglesType[];
  outcomeReasons: GetProductByIdOutcomeReasonType[];
  isShared: boolean;
  writtenStatementPaidAt: string | null;
  writtenStatementUrl: string | null;
  userCertificateTemplateId: string | null;
  dueDate: string | null;
};

export type ProductsResponseType = {
  entities: Array<GetProductResponseType>;
  itemCount: number;
  limit: number;
  page: number;
};

export type GetProductsRequestType = {
  localeName?: string;
  resultStatuses?: Array<ResultStatusType>;
  certificateAvailable?: boolean;
  search?: string | null;
  brand?: {
    id: string;
    include: boolean;
  };
  brandType?: {
    id: string;
    include: boolean;
  };
  checkStatus?: {
    value: string;
    include: boolean;
  };
  productType?: {
    id: string;
    include: boolean;
  };
  publicId?: {
    value: string;
    include: boolean;
  };
  reason?: {
    id: string;
    include: boolean;
  };
  resultStatus?: {
    id: string;
    include: boolean;
  };
  supplier?: {
    value: string;
    include: boolean;
  };
  modelName?: {
    value: string;
    include: boolean;
  };
  sku?: {
    value: string;
    include: boolean;
  };
  date?: {
    fromDate: string;
    untilDate: string;
  };
  sort?: SortProduct;
  limit?: number;
  page?: number;
};

export type UpdateProductRequestType = {
  productId: string;
  dataRequest: {
    supplier?: string | null;
    sku?: string;
    additionalNotes?: string;
  };
};

export type UpdateProductResponseType = GetProductResponseType;

export type CancelUnableProvideRequestType = {
  productId: string;
};

type idUploadedPhotosType = {
  id: string;
};

export type CreateProductRequestType = {
  draftId: string | null;
  name: string | null;
  brand: {
    id: string;
  } | null;
  answerTime: {
    id: string;
  } | null;
  productType: {
    id: string;
  } | null;
  modelName: string | null;
  supplier: string | null;
  sku: string | null;
  additionalNotes: string | null;
  certificateNeeded: boolean;
  itemLabelNeeded: boolean;
  photos: Array<idUploadedPhotosType>;
  userCertificateTemplateId: string | null;
  idempotencyKey?: string;
  modelId?: string | null;
};

export type CreateProductResponseType = {
  message: string;
  result: true;
  userBalance: number;
};

export type CreateDraftRequestType = {
  name: string | null;
  brand: {
    id: string;
  } | null;
  answerTime: {
    id: string;
  } | null;
  productType: {
    id: string;
  } | null;
  modelName: string | null;
  supplier: string | null;
  sku: string | null;
  additionalNotes: string | null;
  certificateNeeded: boolean;
  itemLabelNeeded: boolean;
  photos: Array<idUploadedPhotosType>;
  userCertificateTemplateId: string | null;
  idempotencyKey?: string;
  modelId?: string | null;
};

export type UpdateDraftRequestType = {
  name: string | null;
  brand: {
    id: string;
  } | null;
  answerTime: {
    id: string;
  } | null;
  productType: {
    id: string;
  } | null;
  modelName: string | null;
  supplier: string | null;
  sku: string | null;
  additionalNotes: string | null;
  certificateNeeded: boolean;
  itemLabelNeeded: boolean;
  photos: Array<idUploadedPhotosType>;
  userCertificateTemplateId: string | null;
  productId: string;
  idempotencyKey?: string;
  modelId?: string | null;
};

export type DeleteDraftRequestType = Array<string>;

export type UpdateCertificatePathRequestType = {
  productId: string;
  modelName: string | null;
  productSubType: string | null;
  selectedPhotoIds: Array<string>;
};

export type GetFreeCustomCertificatesResponseType = {
  limit: number;
  used: number;
};

export type GetProductTypeByIdRequestType = {
  productTypeId: string;
  brandId?: string;
  modelId?: string | null;
  localeName?: string;
};

export type GetProductTypeByResponseType = {
  id: string;
  displayName: string;
  angles: Array<AnglesType>;
};

export type PostSurveyAnswersType = {
  surveyId: string;
  answers: [
    {
      questionId: string;
      answerText: string;
    },
  ];
};

export type SurveyQuestionType = {
  id: string;
  text: string;
  answers: Array<string>;
};

export type SurveyAnswerPostType = {
  questionId: string;
  answerText: string;
};

export type SurveyPostDataType = {
  surveyId: string;
  answers: SurveyAnswerPostType[];
};

export type CreateDraftDataType = {
  draftId: string;
  isDuplicateRequest: boolean;
};

export type GetProductByIdRequestType = {
  id: string;
  localeName?: string;
};
