/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserCertificateTemplatesQuery } from '../../../../api/certificates/hooks/useUserCertificateTemplatesQuery';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { SecondButton } from '../../../../componentUI/simple/Button';
import { RadioChipsPictureInPicture } from '../../../../componentUI/simple/Radio/RadioChipsPictureInPicture';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { SkeletonBasic } from '../../../../componentUI/skeleton';
import { useMatchMedia } from '../../../../hooks';
import {
  stylesForButtons,
  TemplateCertificateStyled,
  WrapperSecondButtonStyled,
  WrapperUserTemplateCertificateStyled,
} from './styled';
import { Props } from './types';

const LAST_SELECTED_CUSTOM_CERTIFICATE = 'lastSelectedCustomCertificate';

export const TemplateCertificate = ({
  setIsUserCustomCertificate,
  showCustomCertificate,
  setUserCertificateTemplateId,
  userCertificateTemplateId,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const navigate = useNavigate();

  const { data: dataUserTemplate, isLoading: isLoadingUserTemplate } =
    useUserCertificateTemplatesQuery(showCustomCertificate);

  useEffect(() => {
    if (dataUserTemplate && dataUserTemplate.length > 0) {
      // бизнес логика следующая, если это Draft, то выбирается ранее указанный пользователем,
      // если нет, то последний записанный в LS сертификат если он есть в списке,
      // если нет, то последний созданный по времени, т.е. первый из массива
      const lastSelectedCertificateSave = localStorage.getItem(`${LAST_SELECTED_CUSTOM_CERTIFICATE}`);

      const lastCertificate = dataUserTemplate.find(({ id }) => id === lastSelectedCertificateSave);

      setUserCertificateTemplateId(userCertificateTemplateId || lastCertificate?.id || dataUserTemplate[0].id);

      setIsUserCustomCertificate(true);
    } else {
      setIsUserCustomCertificate(false);
    }
  }, [dataUserTemplate]);

  if (!isLoadingUserTemplate && dataUserTemplate && dataUserTemplate.length <= 0) {
    return null;
  }

  return (
    <TemplateCertificateStyled>
      {isLoadingUserTemplate ? (
        <SkeletonBasic width="312px" height="22px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      ) : (
        <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
          {t('customCertificate.templateCertificate.title')}
        </Title>
      )}

      <WrapperUserTemplateCertificateStyled>
        {isLoadingUserTemplate
          ? new Array(8).fill('1').map((_, index) => {
              return (
                <SkeletonBasic
                  width={isMobile ? '310px' : '184px'}
                  height={isMobile ? '238px' : '238px'}
                  bgColor={COLOR_MAP.controls.skeleton.content1}
                  key={index}
                />
              );
            })
          : dataUserTemplate &&
            dataUserTemplate.map((templateElement) => (
              <RadioChipsPictureInPicture
                key={templateElement.id}
                checkedValue={userCertificateTemplateId || ''}
                onChange={(value) => {
                  setUserCertificateTemplateId(value);
                  localStorage.setItem(`${LAST_SELECTED_CUSTOM_CERTIFICATE}`, `${value}`);
                }}
                url={templateElement.certificateTemplate.path}
                thumbnail={templateElement?.logo?.path}
                name={templateElement.id}
                templateOrientation={templateElement.certificateTemplate.certificateOrientation}
                nameTemplate={templateElement.name}
                value={templateElement.id}
                isBgColor
              />
            ))}
      </WrapperUserTemplateCertificateStyled>

      <WrapperSecondButtonStyled>
        {isLoadingUserTemplate ? (
          <SkeletonBasic width="100%" height="42px" bgColor={COLOR_MAP.controls.skeleton.content1} />
        ) : (
          <SecondButton isStretch style={stylesForButtons} onClick={() => navigate('/account-settings/certificate')}>
            {t('customCertificate.templateCertificate.manageTemplatesButton')}
          </SecondButton>
        )}
      </WrapperSecondButtonStyled>
    </TemplateCertificateStyled>
  );
};
