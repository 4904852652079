/* eslint-disable no-nested-ternary */
import React, { ElementType } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsButton } from './types';
import { buttonTextStyle, commonStyles } from './utils';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};
  ${buttonTextStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;

  border: 2px solid ${({ colorsMap }) => colorsMap?.clickable};
  border-radius: 2px;
  user-select: none;
  background-color: transparent;
  color: ${({ colorsMap }) => colorsMap?.clickable};
  text-decoration: none;

  ${({ size }) => SIZE_STYLE[size]};

  & path {
    stroke: ${({ colorsMap }) => colorsMap?.clickable};
  }

  ${supportHover} {
    &:hover {
      color: ${({ colorsMap }) => colorsMap?.clickableHover};
      border: 2px solid ${({ colorsMap }) => colorsMap?.clickableHover};

      & path {
        stroke: ${({ colorsMap }) => colorsMap?.clickableHover};
      }
    }
  }

  &:active {
    color: ${({ colorsMap }) => colorsMap?.clickablePressed};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickablePressed};

    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickablePressed};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickableDisabled};
    cursor: not-allowed;

    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    }
  }
`;

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.clickableNormal,
  clickableHover: COLOR_MAP.controls.clickableHover,
  clickablePressed: COLOR_MAP.controls.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.clickableDisabled,
};

interface SecondButtonProps extends Partial<PropsButton> {
  linkButton?: string;
  routerLink?: string;
}

export const SecondButton = ({
  size = BUTTON_SIZE.small,
  isStretch = false,
  linkButton,
  routerLink,
  children,
  colorsMap = DEFAULT_COLOR_MAP,
  refBtn,
  ...props
}: SecondButtonProps) => {
  const isLink: Record<never, never> | { as: ElementType; href?: string; to?: string } = linkButton
    ? { as: 'a', href: `${process.env.REACT_APP_ROOT_ENDPOINT}${linkButton}` }
    : routerLink
    ? { as: Link, to: routerLink }
    : {};

  return (
    <ButtonComponent ref={refBtn} size={size} isStretch={isStretch} {...isLink} colorsMap={colorsMap} {...props}>
      {children}
    </ButtonComponent>
  );
};
