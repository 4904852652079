import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { GetBusinessTypesRequestType, GetBusinessTypesResponseType } from './types';

// получение данных для категории Type of business
export const getBusinessTypes = ({ localeName }: GetBusinessTypesRequestType) => {
  return instance.get<GetBusinessTypesResponseType>(`business-types?localeName=${localeName}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
