import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonV2 } from '../../../../componentUI/simple/Button';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';

import { StartingInformationStyled, stylesForButtons, WrapperButtonV2Styled } from './styled';
import { Props } from './types';

export const StartingInformation = ({ pageLink, titleText, buttonText, ButtonIcon }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${pageLink}`);
  };

  return (
    <StartingInformationStyled>
      <Title style={{ textAlign: 'center' }} weight={TITLE_WEIGHT.normal} size={TITLE_SIZE.h4}>
        {titleText}
      </Title>

      <WrapperButtonV2Styled>
        <ButtonV2 isStretch style={stylesForButtons} onClick={handleClick}>
          <ButtonIcon />
          {buttonText}
        </ButtonV2>
      </WrapperButtonV2Styled>
    </StartingInformationStyled>
  );
};
