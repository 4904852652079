import React, { ChangeEvent } from 'react';
import { Text } from '../Text';
import { Input, Label, Switch, ToggleSwitchStyled } from './styled';
import { Props } from './types';

export const ToggleSwitchV2 = ({ isChecked, onToggle, label }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onToggle(e.target.checked);
  };

  return (
    <ToggleSwitchStyled>
      <Label>
        <Input checked={isChecked} type="checkbox" onChange={handleChange} />
        <Switch />
        {label && <Text>{label}</Text>}
      </Label>
    </ToggleSwitchStyled>
  );
};
