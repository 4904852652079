/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { useDeleteLogoForCertificatesMutate } from '../../../../../../api/file/hooks/useDeleteLogoForCertificatesMutate';
import { useLogoForCertificates } from '../../../../../../api/file/hooks/useLogoForCertificates';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { RadioChipsWithPhoto } from '../../../../../../componentUI/simple/Radio/RadioChipsWithPhoto';
import { SkeletonBasic } from '../../../../../../componentUI/skeleton';
import { useMatchMedia } from '../../../../../../hooks';
import { PreviouslyUploadedLogosStyled } from './styled';
import { Props } from './types';

export const PreviouslyUploadedLogos = ({
  setSelectedLogoForCertificate,
  selectedLogoForCertificate,
  isOpenAddCustomCertificate,
}: Props) => {
  const { isMobile } = useMatchMedia();

  const { data: DataUploadedLogos, isLoading: isLoadingUploadedLogos } =
    useLogoForCertificates(isOpenAddCustomCertificate);
  const { mutate: deleteLogo } = useDeleteLogoForCertificatesMutate();

  return (
    <PreviouslyUploadedLogosStyled>
      {isLoadingUploadedLogos
        ? new Array(6).fill('1').map((_, index) => {
            return (
              <SkeletonBasic
                width={isMobile ? '148px' : '100px'}
                height={isMobile ? '148px' : '100px'}
                bgColor={COLOR_MAP.controls.skeleton.content1}
                key={index}
              />
            );
          })
        : DataUploadedLogos &&
          DataUploadedLogos.map((image) => (
            <RadioChipsWithPhoto
              key={image.id}
              checkedValue={selectedLogoForCertificate}
              onChange={(value) => {
                setSelectedLogoForCertificate(value);
              }}
              onDelete={(value) => {
                setSelectedLogoForCertificate('');
                deleteLogo(value);
              }}
              url={image.path}
              name={image.id}
              value={image.id}
              widthPicture="148px"
              heightPicture="148px"
              widthPictureMobile="100px"
              heightPictureMobile="100px"
            />
          ))}
    </PreviouslyUploadedLogosStyled>
  );
};
