import styled from 'styled-components';

import { BannerMobileSubscribes, BannerSubscribes } from '../../../common/images';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 25px 64px 25px 300px;

  background-image: url(${BannerSubscribes});
  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 6px;

  ${truthfulAdaptive.maxWidth.desktopS} {
    padding: 20px;
  }

  ${truthfulAdaptive.maxWidth.desktop} {
    flex-direction: column;
    gap: 16px;
    align-items: center;

    padding: 120px 12px 16px 12px;

    background: no-repeat center top url(${BannerMobileSubscribes});
    background-size: 339px 115px;
  }
`;

const Background = styled.div`
  position: relative;
  margin: 24px 40px 0 40px;
  border-radius: 6px;

  background: linear-gradient(90deg, #eeb41e 4.22%, #f46c20 100%);

  ${truthfulAdaptive.maxWidth.desktop} {
    margin: 16px 16px;
  }
`;

const CLoseWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  background-color: ${COLOR_MAP.accent.orange};
  border-radius: 6px;

  ${truthfulAdaptive.maxWidth.desktop} {
    display: block;
    height: auto;
    width: auto;
    top: 10px;
    right: 10px;
  }
`;

export { Background, CLoseWrapper, Container };
