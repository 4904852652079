import React from 'react';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../../componentUI/skeleton';
import { useMatchMedia } from '../../../../hooks';
import {
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerBlockTwoStyled,
  HeaderMainContainerMobileStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerColumnFourStyled,
  MainContainerColumnOneStyled,
  MainContainerColumnThreeStyled,
  MainContainerColumnTwoStyled,
  MainContainerStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
  TopUpMainSkeletonStyled,
  WrapperBackgroundV1Styled,
  WrapperBackgroundV2Styled,
  WrapperBackgroundV3Styled,
  WrapperContainerColumn,
} from './styled';

export const TopUpMainSkeleton = () => {
  const { isMobile, isTablet, isDesktopS, isDesktop } = useMatchMedia();

  return (
    <TopUpMainSkeletonStyled>
      {isDesktop && (
        <ColumnMenuStyled>
          <HeaderColumnMenuStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </HeaderColumnMenuStyled>

          <MainColumnMenuStyled>
            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="221px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="166px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

            <MainColumnMenuBlockOneStyled>
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </MainColumnMenuBlockOneStyled>

            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </MainColumnMenuStyled>

          <FooterColumnMenuStyled>
            <HeaderColumnMenuBlockOneStyled>
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            </HeaderColumnMenuBlockOneStyled>

            <HeaderColumnMenuBlockTwoStyled>
              <SkeletonBasic width="206px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="24px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </HeaderColumnMenuBlockTwoStyled>

            <SkeletonBasic
              width="160px"
              height="16px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '26px' }}
            />

            <SkeletonBasic
              width="250px"
              height="10px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '40px' }}
            />
          </FooterColumnMenuStyled>
        </ColumnMenuStyled>
      )}

      {!isDesktop && (
        <ColumnMenuTopStyled>
          <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

          <ColumnMenuTopBlockOneStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </ColumnMenuTopBlockOneStyled>
        </ColumnMenuTopStyled>
      )}

      <MainContainerStyled>
        {!isMobile && (
          <HeaderMainContainerStyled>
            {isDesktop && (
              <HeaderMainContainerBlockOneStyled>
                <SkeletonBasic width="400px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <HeaderMainContainerBlockTwoStyled>
                  <SkeletonBasic width="190px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="38px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </HeaderMainContainerBlockTwoStyled>
              </HeaderMainContainerBlockOneStyled>
            )}
          </HeaderMainContainerStyled>
        )}
        {isTablet && (
          <HeaderMainContainerMobileStyled>
            <SkeletonBasic width="400px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </HeaderMainContainerMobileStyled>
        )}

        <WrapperContainerColumn>
          {!isDesktopS && (
            <MainContainerColumnOneStyled>
              <MainMainContainerStyled>
                <SkeletonBasic width="228px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <MainMainContainerBlockOneStyled>
                  <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </MainMainContainerBlockOneStyled>

                <MainMainContainerBlockTwoStyled>
                  <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                  <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                  <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </MainMainContainerBlockTwoStyled>

                <SkeletonBasic width="1080px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainMainContainerStyled>
            </MainContainerColumnOneStyled>
          )}

          {!isDesktopS && (
            <MainContainerColumnTwoStyled>
              <MainContainerColumnThreeStyled>
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="78px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="178px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="178px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="178px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainContainerColumnThreeStyled>

              <MainContainerColumnFourStyled>
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="400px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainContainerColumnFourStyled>
            </MainContainerColumnTwoStyled>
          )}

          {isDesktopS && (
            <MainContainerColumnOneStyled>
              <MainMainContainerStyled>
                <SkeletonBasic width="228px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <WrapperBackgroundV1Styled>
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </WrapperBackgroundV1Styled>

                <SkeletonBasic width="350px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content1} />

                <WrapperBackgroundV2Styled>
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </WrapperBackgroundV2Styled>

                <SkeletonBasic width="228px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <WrapperBackgroundV3Styled>
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                  <SkeletonBasic width="800px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                </WrapperBackgroundV3Styled>

                <WrapperBackgroundV2Styled>
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </WrapperBackgroundV2Styled>

                <SkeletonBasic width="350px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content1} />

                <SkeletonBasic width="800px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainMainContainerStyled>
            </MainContainerColumnOneStyled>
          )}
        </WrapperContainerColumn>
      </MainContainerStyled>
    </TopUpMainSkeletonStyled>
  );
};
