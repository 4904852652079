import { useTranslation } from 'react-i18next';
import { CoverageIcon, LoyaltyIcon, PricingIcon } from '../../../../common/icons/SvgInline/SvgIcons_12';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const LEVELS_STEPS = [
  {
    levelName: 'INSIDER',
    color: '#C49461',
  },
  {
    levelName: 'PRO',
    color: '#52B66A',
  },
  {
    levelName: 'ELITE',
    color: '#79ADFF',
  },
  {
    levelName: 'LEGEND',
    color: '#977CDD',
  },
];

const GRADIENT_COLORS = [
  {
    levelName: 'INSIDER',
    color1: '#765D53',
    color2: '#DDA26B',
  },
  {
    levelName: 'PRO',
    color1: '#118B2D',
    color2: '#7BD790',
  },
  {
    levelName: 'ELITE',
    color1: '#2D79EF',
    color2: '#79ADFF',
  },
  {
    levelName: 'LEGEND',
    color1: '#674EA7',
    color2: '#AC8CFF',
  },
];

export const getLevelBackgroundColor = (level?: string | null): string => {
  if (!level) {
    return COLOR_MAP.accent.grey;
  }

  const upperCaseLevel = level.toUpperCase();

  const levelData = GRADIENT_COLORS.find((item) => item.levelName.toUpperCase() === upperCaseLevel);

  return levelData ? `linear-gradient(89deg, ${levelData.color1} 0%, ${levelData.color2} 100%)` : '#aaaaaa';
};

export const getLevelStepsColor = (level?: string | null): string => {
  if (!level) {
    return COLOR_MAP.accent.grey;
  }

  const upperCaseLevel = level.toUpperCase();

  const levelData = LEVELS_STEPS.find((item) => item.levelName.toUpperCase() === upperCaseLevel);

  return levelData ? levelData.color : '#aaaaaa';
};

export const getLevelColors = (levelName: string) => {
  const levelData = GRADIENT_COLORS.find((level) => level.levelName.toUpperCase() === levelName.toUpperCase());

  return levelData
    ? { startColor: levelData.color2, finishColor: levelData.color1 }
    : { startColor: COLOR_MAP.accent.grey, finishColor: COLOR_MAP.accent.grey };
};

export const useLocalizedHelpCardData = (levelName?: string | null, levelDisplayName?: string | null) => {
  const { t } = useTranslation();

  const loyaltyBgColor = getLevelBackgroundColor(levelName);

  if (!levelName || levelName.toUpperCase() === 'INSIDER') {
    return [
      {
        id: 'loyalty',
        bgColor: loyaltyBgColor,
        Icon: LoyaltyIcon,
        subText: levelName
          ? t('dashboard.helpCard.loyaltyCard.yourLevel')
          : t('dashboard.helpCard.loyaltyCard.unlockLevel'),
        text: levelDisplayName || t('dashboard.helpCard.loyaltyCard.insider'),
        url: '/balance',
        isLocal: true,
        isModal: false,
      },
      {
        id: 'howItWorks',
        bgColor: 'linear-gradient(88deg, #BB9981 0%, #303337 92.71%)',
        Icon: PricingIcon,
        text: t('dashboard.helpCard.howItWorks'),
        url: 'https://b2b.legitgrails.com/how-it-works/',
        isLocal: false,
        isModal: false,
      },
      {
        id: 'brandCoverage',
        bgColor: 'linear-gradient(89deg, #3D7B9E 0%, #2BAFBC 100%)',
        Icon: CoverageIcon,
        text: t('dashboard.helpCard.brandCoverage'),
        url: '/account-settings/subscriptions',
        isLocal: false,
        isModal: true,
      },
    ];
  }

  return [
    {
      id: 'loyalty',
      bgColor: loyaltyBgColor,
      Icon: LoyaltyIcon,
      subText: levelName
        ? t('dashboard.helpCard.loyaltyCard.yourLevel')
        : t('dashboard.helpCard.loyaltyCard.unlockLevel'),
      text: levelDisplayName || t('dashboard.helpCard.loyaltyCard.insider'),
      url: '/balance',
      isLocal: true,
      isModal: false,
    },
    {
      id: 'pricing',
      bgColor: 'linear-gradient(88deg, #BB9981 0%, #303337 92.71%)',
      Icon: PricingIcon,
      text: t('dashboard.helpCard.pricing'),
      url: '/top-up',
      isLocal: true,
      isModal: false,
    },
    {
      id: 'brandCoverage',
      bgColor: 'linear-gradient(89deg, #3D7B9E 0%, #2BAFBC 100%)',
      Icon: CoverageIcon,
      text: t('dashboard.helpCard.brandCoverage'),
      url: '/account-settings/subscriptions',
      isLocal: false,
      isModal: true,
    },
  ];
};
