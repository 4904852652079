/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCertificateTemplatesQuery } from '../../../../../../api/certificates/hooks/useCertificateTemplatesQuery';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../../../componentUI/constants';
import { RadioChipsWithPhoto } from '../../../../../../componentUI/simple/Radio/RadioChipsWithPhoto';
import { Text } from '../../../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../../../componentUI/simple/Title';
import { SkeletonBasic } from '../../../../../../componentUI/skeleton';
import { useMatchMedia } from '../../../../../../hooks';
import { CertificateLayoutStyled, LayoutTemplateStyled, stylesBackgroundButtons } from './styled';
import { Props } from './types';

export const CertificateLayout = ({
  setSelectedLayoutForCertificate,
  selectedLayoutForCertificate,
  isOpenAddCustomCertificate,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  const { data: dataLayoutTemplate, isLoading: isLoadingLayoutTemplate } =
    useCertificateTemplatesQuery(isOpenAddCustomCertificate);

  return (
    <CertificateLayoutStyled>
      <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
        {t('customCertificate.manageTemplates.certificateLayout.title')}
      </Title>

      <Text>{t('customCertificate.manageTemplates.certificateLayout.subtitle')}</Text>

      {isLoadingLayoutTemplate ? (
        <LayoutTemplateStyled>
          <SkeletonBasic
            width={isMobile ? '148px' : '164px'}
            height={isMobile ? '148px' : '164px'}
            bgColor={COLOR_MAP.controls.skeleton.content1}
          />
          <SkeletonBasic width="164px" height="164px" bgColor={COLOR_MAP.controls.skeleton.content1} />
        </LayoutTemplateStyled>
      ) : (
        <LayoutTemplateStyled>
          {dataLayoutTemplate &&
            dataLayoutTemplate.map((image) => (
              <RadioChipsWithPhoto
                key={image.id}
                stylesBackground={stylesBackgroundButtons}
                checkedValue={selectedLayoutForCertificate}
                onChange={(value) => {
                  setSelectedLayoutForCertificate(value);
                }}
                url={image.path}
                name={image.id}
                value={image.id}
                widthPicture="132px"
                heightPicture="132px"
                widthPictureMobile="116px"
                heightPictureMobile="116px"
              />
            ))}
        </LayoutTemplateStyled>
      )}
    </CertificateLayoutStyled>
  );
};
