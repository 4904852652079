import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { createCertificatesTemplates } from '../certificates-api';
import { createCertificatesTemplatesTypes } from '../types';

export const useCreateTemplateMutate = () => {
  const dispatch = useDispatch();
  const client = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEY.createCertificatesTemplates],
    mutationFn: async (template: createCertificatesTemplatesTypes) => {
      const data = await createCertificatesTemplates(template);

      return data;
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getUserCertificateTemplates] });
    },
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
