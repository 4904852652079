import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG_NAME } from '../../componentUI/constants';
import { Button } from '../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';
import { useMatchMedia, useSelectedLanguage } from '../../hooks';
import {
  BrandClassificationStyled,
  CategoryBrandsStyled,
  ColumnStyled,
  ListBrandsStyled,
  SearchBarStyled,
  SeparatorStyled,
  WrapperButtonStyled,
  WrapperCategoryBrandsStyled,
  WrapperColumnStyled,
} from './styled';
import { useBrandCoverageInfo } from '../../api/brandCoverage/hooks';
import { Skeleton } from '../../componentUI/complex/Skeleton';
import { SearchIcon } from '../../common/icons/SvgInline/SvgIcons_2';
import { Input } from '../../componentUI/simple/Input';

export const BrandClassification = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const selectedLanguage = useSelectedLanguage();

  const { data, isLoading } = useBrandCoverageInfo({ localeName: selectedLanguage });

  const [searchTerm, setSearchTerm] = useState('');

  // Filtered brands based on the search term
  const filteredData = data
    ?.map((category) => ({
      ...category,
      coverage: category.coverage
        .map((item) => ({
          ...item,
          brands: item.brands.filter((brand) => brand.toLowerCase().includes(searchTerm.toLowerCase())),
        }))
        .filter((item) => item.brands.length > 0),
    }))
    .filter((category) => category.coverage.length > 0);

  if (isLoading) {
    return <Skeleton variant="text" />;
  }

  return (
    <BrandClassificationStyled>
      <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5}>
        {t('brandClassification.title')}
      </Title>

      <SeparatorStyled />

      <SearchBarStyled>
        <Input
          placeholder={t('authenticationsTablet.search.placeholder')}
          onChange={(value) => setSearchTerm(value)}
          value={searchTerm}
          leftIcon={<SearchIcon />}
          isStretch
          style={{ padding: '10px 10px 10px 38px' }}
        />
      </SearchBarStyled>

      <WrapperCategoryBrandsStyled>
        {filteredData?.map((category) => (
          <CategoryBrandsStyled key={category.id}>
            <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
              {category.displayName}
            </Title>

            <WrapperColumnStyled>
              {category.coverage.map((group) => (
                <ColumnStyled key={group.alpha}>
                  <ListBrandsStyled>
                    <Text weight={TEXT_WEIGHT.bold}>{group.alpha}</Text>
                    <Text>{group.brands.join(', ')}</Text>
                  </ListBrandsStyled>
                </ColumnStyled>
              ))}
            </WrapperColumnStyled>
          </CategoryBrandsStyled>
        ))}
      </WrapperCategoryBrandsStyled>

      <SeparatorStyled />

      <WrapperButtonStyled>
        <Button style={isMobile ? { width: '100%' } : { width: '200px' }} isStretch onClick={onClose}>
          {t('brandClassification.okButton')}
        </Button>
      </WrapperButtonStyled>
    </BrandClassificationStyled>
  );
};
