import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { GetStatisticsResponse } from '../../../../api/product/type';

type DataChartsTypes = Array<{
  id: string;
  parameterOne: keyof GetStatisticsResponse;
  parameterTwo: keyof GetStatisticsResponse;
  text: string;
  chartColor: string;
  backgroundChartColor: string;
}>;

export const useLocalizedDataCharts = (t: (key: string) => string): DataChartsTypes => [
  {
    id: 'completed',
    parameterOne: 'completed',
    parameterTwo: 'submitteditems',
    text: t('dashboard.summary.charts.completed'),
    chartColor: `${COLOR_MAP.accent.green}`,
    backgroundChartColor: `${COLOR_MAP.accent.lightGrey}`,
  },
  {
    id: 'authentic',
    parameterOne: 'authentic',
    parameterTwo: 'completed',
    text: t('dashboard.summary.charts.authenticItems'),
    chartColor: `${COLOR_MAP.accent.gold}`,
    backgroundChartColor: `${COLOR_MAP.accent.lightGrey}`,
  },
];
