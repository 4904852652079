import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getBrandCoverageInfo } from '../BrandCoverage';
import { RequestType } from '../types';

export const useBrandCoverageInfo = (value: RequestType) => {
  const dispatch = useDispatch();
  const { localeName } = value;

  return useQuery({
    queryKey: [QUERY_KEY.useGetBrandCoverageInfo, localeName],
    queryFn: async () => {
      const { data } = await getBrandCoverageInfo(value);

      return data;
    },
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
