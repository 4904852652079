import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CloseIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { BUTTON_SIZE, SecondButton } from '../../../componentUI/simple/Button';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { useMatchMedia } from '../../../hooks';
import { BlackFrImage, BlackFrImageDesktop, BlackFrImageMobile } from './assets';

export const STORADGE_KEY = 'ewqecvfvffdadasdfvgfeg';

const COLOR_MAP_BTN = {
  clickable: COLOR_MAP.controls.light.clickable,
  clickableHover: COLOR_MAP.controls.light.clickableHover,
  clickablePressed: COLOR_MAP.controls.light.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.light.clickableDisabled,
};

const Background = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 24px 40px 0 40px;
  border-radius: 6px;

  background: linear-gradient(90deg, #cbad73 4.3%, #000 99.92%);
  ${truthfulAdaptive.maxWidth.desktop} {
    margin: 16px 16px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding-right: 55px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    justify-content: space-between;
  }
`;

const StyledTitle = styled(Title)`
  ${truthfulAdaptive.minWidth.desktop1440} {
    margin-bottom: 8px;
  }

  ${truthfulAdaptive.minWidth.desktop1600} {
    max-width: 735px;
    margin-bottom: 8px;
  }
`;

const CLoseWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  border-radius: 6px;
  background-color: rgba(188, 188, 188, 0.16);

  ${truthfulAdaptive.maxWidth.desktop} {
    background-color: black;
    display: block;
    height: auto;
    width: auto;
    top: 10px;
    right: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  background-image: url(${BlackFrImageMobile});
  background-size: 398px 125px;
  background-position: center 0;
  background-repeat: no-repeat;
  padding-top: 125px;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 16px;

  ${truthfulAdaptive.minWidth.desktop} {
    height: 187px;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;

    background-image: url(${BlackFrImageDesktop});
    background-size: 197px 187px;
    padding-left: 197px;
    background-position: 0 0;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    height: 128px;

    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    background-image: url(${BlackFrImage});
    background-size: 197px 128px;
  }
`;

export const BlackFridayBanner = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { isTablet } = useMatchMedia();

  const [isHidden, setIsHidden] = useState(Boolean(localStorage.getItem(STORADGE_KEY)));

  const closeCallback = () => {
    setIsHidden(true);
    localStorage.setItem(STORADGE_KEY, 'true');
  };

  if (!pathname.includes('dashboard') || isHidden) return null;

  return (
    <Background>
      <StyledContentWrapper>
        <div>
          <StyledTitle
            size={TITLE_SIZE.h4}
            colorText={COLOR_MAP.text.white}
            style={{ textAlign: isTablet ? 'center' : undefined, marginBottom: '10px' }}
          >
            Buy any package before November 27th and get 30% more credits on top of your purchase
          </StyledTitle>
          <Title
            size={TITLE_SIZE.h6}
            colorText={COLOR_MAP.text.white}
            style={{ textAlign: isTablet ? 'center' : undefined, fontWeight: '400' }}
          >
            Promocode <b>BF-LegitGrails-23</b>
          </Title>
        </div>
        <div style={{ width: isTablet ? '100%' : undefined }}>
          <SecondButton
            onClick={() => navigate('/top-up')}
            colorsMap={COLOR_MAP_BTN}
            size={BUTTON_SIZE.extraSmall}
            style={{ minWidth: '200px', fontSize: '18px' }}
            isStretch={isTablet}
          >
            USE IT NOW
          </SecondButton>
        </div>
      </StyledContentWrapper>

      <CLoseWrapper>
        <CloseIcon
          size="32"
          color={COLOR_MAP.text.white}
          props={{ onClick: closeCallback, style: { cursor: 'pointer' } }}
        />
      </CLoseWrapper>
    </Background>
  );
};
