/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { DropdownIndicator } from '../../../../../common';
import { AuthPageType } from '../../../../../common/constants';
import { useSelectedLanguage } from '../../../../../hooks';
import { useGetSortingParameters } from '../helper';
import { Props, SelectedOrderSortType } from './types';

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { dashboard } = AuthPageType;

const OptionComp = ({ label, icon }: { label: string; icon: JSX.Element }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {icon}
      {label}
    </div>
  );
};

export const TableHeaderIsMobile = ({ setSelectedFilter, currentPage }: Props) => {
  const selectedLanguage = useSelectedLanguage();

  const sortingValues = useGetSortingParameters(currentPage);

  // содержит текущий выбранный фильтр
  const [selectedOrderSort, setSelectedOrderSort] = useState<SelectedOrderSortType | null>(sortingValues[0] || null);

  useEffect(() => {
    setSelectedFilter(sortingValues[0].value || null);
    setSelectedOrderSort(sortingValues[0] || null);
  }, [selectedLanguage]);

  if (!currentPage) {
    return null;
  }

  // получаем информацию находиться ли пользователь на странице dashboard
  const isDashboard = currentPage === dashboard;

  // не показываем этот блок на данных страницах
  if (isDashboard) {
    return null;
  }

  return (
    <Select
      components={{ DropdownIndicator }}
      classNamePrefix="custom-select"
      options={sortingValues}
      value={selectedOrderSort}
      isSearchable={false}
      onChange={(option) => {
        if (option) {
          setSelectedFilter(option.value);
          setSelectedOrderSort(option);
        }
      }}
      // @ts-ignore ждет из функции стрингу, не получается переопределить
      getOptionLabel={OptionComp}
    />
  );
};
