import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import {
  ChangeEmailRequestType,
  ChangeEmailResponseType,
  ConfirmEmailRequestType,
  ConfirmEmailResponseType,
  ForgotPasswordRequestType,
  ForgotPasswordResponseType,
  GetCheckAuthRequestType,
  GetCheckAuthResponseType,
  GetFindMe,
  GetLocaleResponseType,
  GetResendDigitCodeRequestType,
  GetResendDigitCodeResponseType,
  GetSurveysRequestType,
  LoginRequestType,
  LoginResponseType,
  RefreshTokenRequestRequestType,
  RefreshTokenRequestResponseType,
  RegisterRequestType,
  RegisterResponseType,
  ResetPasswordRequestType,
  ResetPasswordResponseType,
  UpdateLocaleRequestType,
  UpdateLocaleResponseType,
  UpdateMeRequestType,
  UpdateMeResponseType,
} from './authTypes';

// signup - регистрация нового пользователя
export const register = (data: RegisterRequestType) => {
  return instance.post<RegisterResponseType>('auth/email/register', data);
};

// signin - авторизация пользователя
export const login = (data: LoginRequestType) => {
  return instance.post<LoginResponseType>('auth/email/login', data);
};

// получение данных об аккаунте пользователя
export const getFindMe = (data: string | undefined) => {
  return instance.get<GetFindMe>('auth/find-me', {
    headers: { Authorization: `Bearer ${data}` },
  });
};

// обновление данных об аккаунте пользователя
export const updateMe = (params: Partial<UpdateMeRequestType>) => {
  return instance.patch<UpdateMeResponseType>('auth/me', params, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// изменение пароля если пользователь его "забыл" на странице авторизации
export const forgotPassword = (data: ForgotPasswordRequestType) => {
  return instance.post<ForgotPasswordResponseType>('auth/forgot/password', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// смена пароля пользователя
export const resetPassword = (data: ResetPasswordRequestType) => {
  return instance.post<ResetPasswordResponseType>('auth/reset/password', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// подтверждение имейла пользователя при регистрации
export const confirmEmail = (data: ConfirmEmailRequestType) => {
  return instance.post<ConfirmEmailResponseType>('auth/email/confirm', data);
};

// перезапрос токена авторизации при истечении строка его жизни
export const refreshTokenRequest = (refreshToken: RefreshTokenRequestRequestType) => {
  return instance.post<RefreshTokenRequestResponseType>(
    'auth/refresh',
    {},
    { headers: { Authorization: `Bearer ${refreshToken}` } },
  );
};

// проверка сохраненного у пользователя токена на актуальность
export const getCheckAuth = (jwtToken: GetCheckAuthRequestType) => {
  return instance.get<GetCheckAuthResponseType>('auth/check', {
    headers: { Authorization: `Bearer ${jwtToken}` },
  });
};

// смена имейла пользователя
export const changeEmail = (newEmail: ChangeEmailRequestType) => {
  return instance.patch<ChangeEmailResponseType>('auth/me/change-email', newEmail, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// перезапрос кода подтверждения имейла при регистрации
export const getResendDigitCode = (email: GetResendDigitCodeRequestType) => {
  return instance.post<GetResendDigitCodeResponseType>('auth/email/resend', email);
};

// Получить язык сайта который пользователь выбрал ранее
export const getLocale = () => {
  return instance.get<GetLocaleResponseType>('auth/locale', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// Изменить язык сайта, который пользователь выбрал на сайте
export const updateLocale = (data: UpdateLocaleRequestType) => {
  return instance.patch<UpdateLocaleResponseType>('auth/update', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// Получение данных для прохождение опроса для нового пользователя
export const getSurveys = () => {
  return instance.get<GetSurveysRequestType>('auth/surveys', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
