import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortProduct } from '../../../../../api/product/type';
import { TableTitleChevronDownIcon, TableTitleChevronUpIcon } from '../../../../../common/icons/SvgInline/SvgIcons_11';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Checkbox } from '../../../../../componentUI/simple/Checkbox';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../../componentUI/simple/Text';
import { changeStatusAllCheckboxes } from '../../../helpers';
import { handleClickChevronButton, processingClickFilter } from '../helper';
import {
  ActionTitleStyled,
  AnswerTitleStyled,
  BrandTitleStyled,
  ButtonIconStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ImgTitleStyled,
  ModelTitleStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  SKUTitleStyled,
  StatusTitleStyled,
  TableHeaderStyled,
  WrapperChevronStyled,
} from './styled';
import { Props } from './types';

const {
  resultStatusUp,
  resultStatusDown,
  createdAtUp,
  createdAtDown,
  answerTimeUp,
  answerTimeDown,
  checkStatusUp,
  checkStatusDown,
} = SortProduct;

export const TableHeaderIsDesktop = ({
  incomingFilters,
  setSelectedFilter,
  selectedFilter,
  tableColumn,
  allChecksDraftsElements,
  setAllChecksDraftsElements,
  currentPage,
}: Props) => {
  const { t } = useTranslation();
  const { statusFilter, dateFilter, answerFilter, outcomeFilter } = incomingFilters;
  const { draft, order, status, date, brand, model, sku, answer, outcome, action } = tableColumn;

  const isDraftPage = currentPage === 'drafts';

  const [isCheckAllDraftElement, setIsCheckAllDraftElement] = useState(false);

  return (
    <TableHeaderStyled isDraftPage={isDraftPage}>
      {draft && (
        <CheckboxTitleStyled>
          <Checkbox
            checked={isCheckAllDraftElement}
            onChange={() => {
              setIsCheckAllDraftElement((prev) => {
                changeStatusAllCheckboxes({
                  allChecksDrafts: allChecksDraftsElements,
                  setAllChecksDrafts: setAllChecksDraftsElements,
                  isCheck: !prev,
                });
                return !prev;
              });
            }}
          />
        </CheckboxTitleStyled>
      )}

      <ImgTitleStyled />

      {order && (
        <OrderNumberTitleStyled>
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.orderNumber')}
          </Text>
        </OrderNumberTitleStyled>
      )}

      {status && (
        <StatusTitleStyled>
          {statusFilter && (
            <WrapperChevronStyled>
              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={checkStatusUp}
              >
                {processingClickFilter(selectedFilter, checkStatusUp) && <TableTitleChevronUpIcon />}
              </ButtonIconStyled>

              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={checkStatusDown}
              >
                {processingClickFilter(selectedFilter, checkStatusDown) && <TableTitleChevronDownIcon />}
              </ButtonIconStyled>
            </WrapperChevronStyled>
          )}

          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.status')}
          </Text>
        </StatusTitleStyled>
      )}

      {date && (
        <DateTitleStyled>
          {dateFilter && (
            <WrapperChevronStyled>
              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={createdAtUp}
              >
                {processingClickFilter(selectedFilter, createdAtUp) && <TableTitleChevronUpIcon />}
              </ButtonIconStyled>

              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={createdAtDown}
              >
                {processingClickFilter(selectedFilter, createdAtDown) && <TableTitleChevronDownIcon />}
              </ButtonIconStyled>
            </WrapperChevronStyled>
          )}

          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.date')}
          </Text>
        </DateTitleStyled>
      )}

      {brand && (
        <BrandTitleStyled>
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.brand')}
          </Text>
        </BrandTitleStyled>
      )}

      {model && (
        <ModelTitleStyled>
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.model')}
          </Text>
        </ModelTitleStyled>
      )}

      {sku && (
        <SKUTitleStyled>
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.sku')}
          </Text>
        </SKUTitleStyled>
      )}

      {answer && (
        <AnswerTitleStyled>
          {answerFilter && (
            <WrapperChevronStyled>
              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={answerTimeUp}
              >
                {processingClickFilter(selectedFilter, answerTimeUp) && <TableTitleChevronUpIcon />}
              </ButtonIconStyled>

              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={answerTimeDown}
              >
                {processingClickFilter(selectedFilter, answerTimeDown) && <TableTitleChevronDownIcon />}
              </ButtonIconStyled>
            </WrapperChevronStyled>
          )}

          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.answerTime')}
          </Text>
        </AnswerTitleStyled>
      )}

      {outcome && (
        <OutcomeTitleStyled>
          {outcomeFilter && (
            <WrapperChevronStyled>
              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={resultStatusUp}
              >
                {processingClickFilter(selectedFilter, resultStatusUp) && <TableTitleChevronUpIcon />}
              </ButtonIconStyled>

              <ButtonIconStyled
                onClick={(event) => handleClickChevronButton(event, selectedFilter, setSelectedFilter)}
                data-filter-id={resultStatusDown}
              >
                {processingClickFilter(selectedFilter, resultStatusDown) && <TableTitleChevronDownIcon />}
              </ButtonIconStyled>
            </WrapperChevronStyled>
          )}

          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.outcome')}
          </Text>
        </OutcomeTitleStyled>
      )}

      {action && (
        <ActionTitleStyled>
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey} weight={TEXT_WEIGHT.bold}>
            {t('authenticationsTablet.tableHeaders.action')}
          </Text>
        </ActionTitleStyled>
      )}
    </TableHeaderStyled>
  );
};
