import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { BrandCoverageResponseType, RequestType } from './types';

export const getBrandCoverageInfo = (data: RequestType) => {
  const { localeName } = data;

  return instance.get<BrandCoverageResponseType>(`/brands/coverage?localeName=${localeName}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
