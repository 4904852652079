import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getBusinessTypes } from '../businessTypes-api';
import { GetBusinessTypesRequestType } from '../types';
import { ErrorType } from './types';

export const useGetBusinessTypesQuery = (dataBusinessTypes: GetBusinessTypesRequestType) => {
  const dispatch = useDispatch();

  const { localeName } = dataBusinessTypes;

  return useQuery({
    queryKey: [QUERY_KEY.useGetBusinessTypesQuery, localeName],
    queryFn: async () => {
      const { data } = await getBusinessTypes(dataBusinessTypes);

      return data;
    },

    // преобразуем полученные данные в список для react-select
    select: (data) => {
      return data.map(({ id, displayName }) => {
        return {
          value: id,
          label: displayName,
        };
      });
    },
    // список типов статичен, перезапрос не требуется
    cacheTime: Infinity,
    staleTime: Infinity,

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
