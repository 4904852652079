/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateTemplateMutate } from '../../../../../../api/certificates/hooks/useCreateTemplateMutate';
import { QUERY_KEY } from '../../../../../../common';
import { Button } from '../../../../../../componentUI/simple/Button';
import { stylesForButtons, WrapperButtonStyled } from './styled';
import { Props } from './types';

export const AddCertificateTemplate = ({
  setSelectedLogoForCertificate,
  setSelectedLayoutForCertificate,
  setTemplateName,
  isEnableAddBtnTemplate,
  templateName,
  selectedLogoForCertificate,
  selectedLayoutForCertificate,
}: Props) => {
  const { t } = useTranslation();
  const client = useQueryClient();

  const {
    mutate: createTemplate,
    isSuccess: isSuccessCreateTemplate,
    isLoading: isLoadingCreateTemplate,
  } = useCreateTemplateMutate();

  useEffect(() => {
    if (isSuccessCreateTemplate) {
      setSelectedLogoForCertificate('');
      setSelectedLayoutForCertificate('');
      setTemplateName('');
      client.invalidateQueries({ queryKey: [QUERY_KEY.getUserCertificateTemplates] });
    }
  }, [isSuccessCreateTemplate]);

  return (
    <WrapperButtonStyled>
      <Button
        isStretch
        style={stylesForButtons}
        disabled={!isEnableAddBtnTemplate || isLoadingCreateTemplate}
        onClick={() => {
          createTemplate({
            name: templateName,
            certificateTemplateId: selectedLayoutForCertificate,
            logoId: selectedLogoForCertificate === '' ? null : selectedLogoForCertificate,
            qrCodeNeeded: true,
            isDefault: false,
          });
        }}
      >
        {t('customCertificate.manageTemplates.AddCertificateTemplate.addButton')}
      </Button>
    </WrapperButtonStyled>
  );
};
