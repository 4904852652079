import styled from 'styled-components';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';

const BrandClassificationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow: hidden;
`;

const SeparatorStyled = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${COLOR_MAP.accent.grey01};
`;

const WrapperCategoryBrandsStyled = styled.div`
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  gap: 24px;

  overflow-y: auto;
`;

const CategoryBrandsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WrapperColumnStyled = styled.div`
  column-count: 2; /* Number of masonry columns */
  column-gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    column-count: 1; /* Single column on mobile */
  }
`;

const ColumnStyled = styled.div`
  break-inside: avoid; /* Prevents breaking content within the column */
  margin-bottom: 16px;
`;

const ListBrandsStyled = styled.div`
  display: flex;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;

const WrapperButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SearchBarStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export {
  BrandClassificationStyled,
  CategoryBrandsStyled,
  ColumnStyled,
  ListBrandsStyled,
  SeparatorStyled,
  WrapperButtonStyled,
  WrapperCategoryBrandsStyled,
  WrapperColumnStyled,
};
