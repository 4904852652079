import React, { HTMLAttributes } from 'react';
import { COLOR_MAP } from '../../../componentUI/colorsMap';

/* eslint-disable max-len */
const ArrowLeftIcon = ({ size = '16' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6667 8H3.33337" stroke="#CBAD73" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.00004 12.6663L3.33337 7.99967L8.00004 3.33301"
      stroke="#CBAD73"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EditIcon = ({ size = '24' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20H21" stroke="#CBAD73" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18 2.87891C17.4374 2.87891 16.8978 3.1024 16.5 3.50023L4 16.0002L3 20.0002L7 19.0002L19.5 6.50023C19.697 6.30324 19.8532 6.06939 19.9598 5.81202C20.0665 5.55465 20.1213 5.2788 20.1213 5.00023C20.1213 4.72165 20.0665 4.4458 19.9598 4.18843C19.8532 3.93106 19.697 3.69721 19.5 3.50023C19.303 3.30324 19.0692 3.14699 18.8118 3.04038C18.5544 2.93378 18.2786 2.87891 18 2.87891Z"
      stroke="#CBAD73"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AlertTriangleIcon = ({ size = '24' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.82002 18.0002L10.29 3.8602C10.4683 3.56631 10.7193 3.32332 11.0188 3.15469C11.3184 2.98605 11.6563 2.89746 12 2.89746C12.3438 2.89746 12.6817 2.98605 12.9812 3.15469C13.2807 3.32332 13.5318 3.56631 13.71 3.8602L22.18 18.0002C22.3547 18.3026 22.4471 18.6455 22.448 18.9947C22.449 19.3439 22.3585 19.6873 22.1856 19.9907C22.0127 20.2941 21.7633 20.547 21.4623 20.7241C21.1613 20.9012 20.8192 20.9964 20.47 21.0002H3.53002C3.18082 20.9964 2.83871 20.9012 2.53773 20.7241C2.23675 20.547 1.98738 20.2941 1.81445 19.9907C1.64151 19.6873 1.55103 19.3439 1.55201 18.9947C1.55299 18.6455 1.64539 18.3026 1.82002 18.0002Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 9V13" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 17H12.01" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const PhotoCheckIcon = ({ size = '22' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3334 5.5L8.25002 15.5833L3.66669 11"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadIcon = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
      stroke="#CBAD73"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83331 8.33301L9.99998 12.4997L14.1666 8.33301"
      stroke="#CBAD73"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 12.5V2.5" stroke="#CBAD73" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SearchIcon = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
      stroke="#888888"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 17.5L13.875 13.875"
      stroke="#888888"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TrashIcon = ({
  color = '#020202',
  size = '24',
  onClick,
}: {
  color?: string;
  size?: string;
  onClick?: () => void;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M3 6H5H21" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 11V17" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 11V17" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Calendar = ({ isDisabled = false, props = {} }: { isDisabled?: boolean; props?: HTMLAttributes<SVGElement> }) => {
  const strokeColor = isDisabled ? COLOR_MAP.controls.disabled : COLOR_MAP.controls.normal;
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.3333 3.33325H4.66667C3.74619 3.33325 3 4.07944 3 4.99992V16.6666C3 17.5871 3.74619 18.3333 4.66667 18.3333H16.3333C17.2538 18.3333 18 17.5871 18 16.6666V4.99992C18 4.07944 17.2538 3.33325 16.3333 3.33325Z"
        stroke={strokeColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8333 1.66675V5.00008"
        stroke={strokeColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16675 1.66675V5.00008"
        stroke={strokeColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 8.33325H18" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ArrowRight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke="#AAAAAA" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 5L19 12L12 19" stroke="#AAAAAA" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CloseIcon = ({
  size = '40',
  color = '#020202',
  props,
}: {
  size?: string;
  color?: string;
  props?: HTMLAttributes<SVGElement>;
}) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M30 10L10 30" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 10L30 30" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CloseIconV2 = ({ size = '14', color = '#FFFFFF' }: { size?: string; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
    <path d="M10.5 3.5L3.5 10.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 3.5L10.5 10.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CloseIconV3 = ({ size = '24', color = '#FFFFFF' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export {
  AlertTriangleIcon,
  ArrowLeftIcon,
  ArrowRight,
  Calendar,
  CloseIcon,
  CloseIconV2,
  CloseIconV3,
  DownloadIcon,
  EditIcon,
  PhotoCheckIcon,
  SearchIcon,
  TrashIcon,
};
