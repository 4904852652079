import React from 'react';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';
import { useMatchMedia } from '../../../hooks';
import {
  AppRouterSkeletonWrapperStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  FooterMainContainerBlockOneStyled,
  FooterMainContainerBlockThreeStyled,
  FooterMainContainerBlockTwoStyled,
  FooterMainContainerMobileBlockOneStyled,
  FooterMainContainerMobileBlockTwoStyled,
  FooterMainContainerMobileStyled,
  FooterMainContainerStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerBlockTwoStyled,
  HeaderMainContainerMobileStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerStyled,
  MainMainContainerBlockFiveStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockSixStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerMobileBlockFiveStyled,
  MainMainContainerMobileBlockFourStyled,
  MainMainContainerMobileBlockOneStyled,
  MainMainContainerMobileBlockSixStyled,
  MainMainContainerMobileBlockThreeStyled,
  MainMainContainerMobileBlockTwoStyled,
  MainMainContainerMobileStyled,
  MainMainContainerStyled,
} from './styled';

export const AppRouterSkeleton = () => {
  const { isMobile, isDesktop, isTablet } = useMatchMedia();

  return (
    <AppRouterSkeletonWrapperStyled>
      {isDesktop && (
        <ColumnMenuStyled>
          <HeaderColumnMenuStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </HeaderColumnMenuStyled>

          <MainColumnMenuStyled>
            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="221px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="166px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

            <MainColumnMenuBlockOneStyled>
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </MainColumnMenuBlockOneStyled>

            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </MainColumnMenuStyled>

          <FooterColumnMenuStyled>
            <HeaderColumnMenuBlockOneStyled>
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            </HeaderColumnMenuBlockOneStyled>

            <HeaderColumnMenuBlockTwoStyled>
              <SkeletonBasic width="206px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="24px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </HeaderColumnMenuBlockTwoStyled>

            <SkeletonBasic
              width="160px"
              height="16px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '26px' }}
            />

            <SkeletonBasic
              width="250px"
              height="10px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '40px' }}
            />
          </FooterColumnMenuStyled>
        </ColumnMenuStyled>
      )}

      {!isDesktop && (
        <ColumnMenuTopStyled>
          <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

          <ColumnMenuTopBlockOneStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </ColumnMenuTopBlockOneStyled>
        </ColumnMenuTopStyled>
      )}

      <MainContainerStyled>
        {!isMobile && (
          <HeaderMainContainerStyled>
            {isDesktop && (
              <HeaderMainContainerBlockOneStyled>
                <SkeletonBasic width="400px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <HeaderMainContainerBlockTwoStyled>
                  <SkeletonBasic width="190px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="38px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </HeaderMainContainerBlockTwoStyled>
              </HeaderMainContainerBlockOneStyled>
            )}

            <SkeletonBasic width="228px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="140px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </HeaderMainContainerStyled>
        )}

        {isMobile && (
          <HeaderMainContainerMobileStyled>
            <SkeletonBasic width="178px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="178px" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </HeaderMainContainerMobileStyled>
        )}

        {!isMobile && (
          <MainMainContainerStyled>
            <MainMainContainerBlockOneStyled>
              <MainMainContainerBlockThreeStyled>
                <SkeletonBasic width="145px" height="145px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="145px" height="145px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainMainContainerBlockThreeStyled>

              <MainMainContainerBlockFourStyled>
                <MainMainContainerBlockFiveStyled>
                  <SkeletonBasic width="125px" height="19px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="125px" height="19px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </MainMainContainerBlockFiveStyled>

                <SkeletonBasic
                  width="201px"
                  height="19px"
                  bgColor={COLOR_MAP.controls.skeleton.content2}
                  style={{ marginTop: '34px' }}
                />

                <MainMainContainerBlockSixStyled>
                  <SkeletonBasic width="272px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="272px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="272px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </MainMainContainerBlockSixStyled>
              </MainMainContainerBlockFourStyled>
            </MainMainContainerBlockOneStyled>

            <MainMainContainerBlockTwoStyled>
              <SkeletonBasic
                width="420px"
                height={isTablet ? '148px' : '80px'}
                bgColor={COLOR_MAP.controls.skeleton.content1}
              />
              <SkeletonBasic
                width="420px"
                height={isTablet ? '148px' : '80px'}
                bgColor={COLOR_MAP.controls.skeleton.content1}
              />
              <SkeletonBasic
                width="420px"
                height={isTablet ? '148px' : '80px'}
                bgColor={COLOR_MAP.controls.skeleton.content1}
              />
            </MainMainContainerBlockTwoStyled>
          </MainMainContainerStyled>
        )}

        {isMobile && (
          <MainMainContainerMobileStyled>
            <MainMainContainerMobileBlockOneStyled>
              <SkeletonBasic
                width="128px"
                height="180px"
                bgColor={COLOR_MAP.controls.skeleton.content1}
                style={{ flexShrink: ' 0' }}
              />
              <SkeletonBasic
                width="128px"
                height="180px"
                bgColor={COLOR_MAP.controls.skeleton.content1}
                style={{ flexShrink: ' 0' }}
              />
              <SkeletonBasic
                width="128px"
                height="180px"
                bgColor={COLOR_MAP.controls.skeleton.content1}
                style={{ flexShrink: ' 0' }}
              />
            </MainMainContainerMobileBlockOneStyled>

            <SkeletonBasic
              width="134px"
              height="19px"
              bgColor={COLOR_MAP.controls.skeleton.content2}
              style={{ marginTop: '36px' }}
            />

            <MainMainContainerMobileBlockTwoStyled>
              <MainMainContainerMobileBlockThreeStyled>
                <SkeletonBasic width="120px" height="120px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="120px" height="120px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainMainContainerMobileBlockThreeStyled>

              <MainMainContainerMobileBlockFourStyled>
                <SkeletonBasic width="174px" height="19px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <MainMainContainerMobileBlockSixStyled>
                  <SkeletonBasic width="115px" height="19px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="63px" height="19px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                </MainMainContainerMobileBlockSixStyled>
              </MainMainContainerMobileBlockFourStyled>

              <MainMainContainerMobileBlockFiveStyled>
                <SkeletonBasic width="295px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="295px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="295px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </MainMainContainerMobileBlockFiveStyled>
            </MainMainContainerMobileBlockTwoStyled>
          </MainMainContainerMobileStyled>
        )}

        {!isMobile && (
          <FooterMainContainerStyled>
            <FooterMainContainerBlockOneStyled>
              <SkeletonBasic width="339px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="80px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </FooterMainContainerBlockOneStyled>

            <FooterMainContainerBlockTwoStyled>
              <SkeletonBasic width="100px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="209px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="85px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="85px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="265px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="50px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="124px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="50px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </FooterMainContainerBlockTwoStyled>

            <FooterMainContainerBlockThreeStyled>
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
              <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            </FooterMainContainerBlockThreeStyled>
          </FooterMainContainerStyled>
        )}

        {isMobile && (
          <FooterMainContainerMobileStyled>
            <SkeletonBasic width="315px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="600px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />

            <FooterMainContainerMobileBlockOneStyled>
              <SkeletonBasic
                width="60px"
                height="60px"
                bgColor={COLOR_MAP.controls.skeleton.content2}
                style={{ position: 'absolute', top: '18px', right: '18px' }}
              />
              <SkeletonBasic width="174px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content2} />

              <FooterMainContainerMobileBlockTwoStyled>
                <SkeletonBasic width="155px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="174px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="108px" height="6px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </FooterMainContainerMobileBlockTwoStyled>

              <FooterMainContainerMobileBlockTwoStyled>
                <SkeletonBasic width="108px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="215px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="122px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </FooterMainContainerMobileBlockTwoStyled>

              <SkeletonBasic width="268px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </FooterMainContainerMobileBlockOneStyled>

            <FooterMainContainerMobileBlockOneStyled>
              <SkeletonBasic
                width="60px"
                height="60px"
                bgColor={COLOR_MAP.controls.skeleton.content2}
                style={{ position: 'absolute', top: '18px', right: '18px' }}
              />
              <SkeletonBasic width="174px" height="14px" bgColor={COLOR_MAP.controls.skeleton.content2} />

              <FooterMainContainerMobileBlockTwoStyled>
                <SkeletonBasic width="155px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="174px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="108px" height="6px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </FooterMainContainerMobileBlockTwoStyled>

              <FooterMainContainerMobileBlockTwoStyled>
                <SkeletonBasic width="108px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="215px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                <SkeletonBasic width="122px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </FooterMainContainerMobileBlockTwoStyled>

              <SkeletonBasic width="268px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </FooterMainContainerMobileBlockOneStyled>
          </FooterMainContainerMobileStyled>
        )}
      </MainContainerStyled>
    </AppRouterSkeletonWrapperStyled>
  );
};
