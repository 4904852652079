import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getFindMe } from '../index';
import { ErrorType } from './types';

export const useAccountInfoQuery = (jwtKey: string | null, enabled = true) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getAccountInfo],
    queryFn: async () => {
      const { data } = await getFindMe(jwtKey || Cookies.get('jwt'));

      return data;
    },
    enabled,
    cacheTime: 10000,
    onError: (error: ErrorType) => {
      // ошибку "Unauthorized" пользователю не показываем
      if (error?.response?.data?.message !== 'Unauthorized') {
        dispatch(
          addToast({
            text: error?.response?.data?.message || 'Something is wrong, please try again.',
          }),
        );
      }
    },
  });
};
