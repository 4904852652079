import React, { ChangeEvent, useEffect, useState } from 'react';
import { TriangleError } from '../../../common/icons/SvgInline/SvgIcons_7';
import { Container, Description, ErrorContainer, ErrorDescription, Label, StyledTextarea } from './styled';
import { TextareaProps } from './types';

export const TextareaV2 = ({ onChange, onErrorChange, placeholder, label, maxLength = 200 }: TextareaProps) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(value.length > maxLength);
    onErrorChange(value.length > maxLength);
  }, [value, maxLength, onErrorChange]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    onChange(inputValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Container>
      {label && <Label isError={isError}>{label}</Label>}
      <StyledTextarea
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isError={isError}
        isFocused={isFocused}
      />
      <Description isError={isError}>
        {isError ? (
          <ErrorContainer>
            <TriangleError />
            <ErrorDescription>Character limit exceeded</ErrorDescription>
          </ErrorContainer>
        ) : (
          `Max ${maxLength} characters`
        )}
      </Description>
    </Container>
  );
};
