import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const ManageTemplatesStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;

export { ManageTemplatesStyled };
