import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';

const LogoCertificateStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;
const UploadLogoStyled = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
    align-items: start;
    flex-direction: column;
  }
`;

const WrapperSecondButtonStyled = styled.div`
  min-width: 200px;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const stylesForButtons: CSSProperties = {
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export { LogoCertificateStyled, stylesForButtons, UploadLogoStyled, WrapperSecondButtonStyled };
