import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getSurveys } from '../auth-api';
import { ErrorType } from './types';

export const useGetSurveysQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.useGetSurveys],
    queryFn: async () => {
      const { data } = await getSurveys();

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
