import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../componentUI/simple/Text';

const TemplateCertificateStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;

const WrapperUserTemplateCertificateStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 12px;
  }
`;

const WrapperSecondButtonStyled = styled.div`
  width: 238px;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const stylesForButtons: CSSProperties = {
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export { stylesForButtons, TemplateCertificateStyled, WrapperUserTemplateCertificateStyled, WrapperSecondButtonStyled };
