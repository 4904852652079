/* eslint-disable react-hooks/exhaustive-deps */
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'react-tabs-scrollable';
import 'react-tabs-scrollable/dist/rts.css';
import { TabItemLeftArrowIcon, TabItemRightArrowIcon } from '../../common/icons/SvgInline/SvgIcons_12';
import { TypeTitle } from './styled';
import './styled.css';
import { Props } from './types';

export const TabsScrollable = ({ tabs }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(0);
  const [isStartingPosition, setIsStartingPosition] = useState(true);
  const [isEndingPosition, setIsEndPosition] = useState(false);

  const onTabClick = (e: BaseSyntheticEvent, index: number) => {
    setActiveTab(index);
  };

  // устанавливаем фокус на активной в данным момент вкладке
  useEffect(() => {
    const selectedTab = tabs.find(({ isChosen }) => {
      return isChosen;
    });

    setActiveTab(selectedTab?.indexTab || 0);
  }, [pathname]);

  return (
    <Tabs
      mode="scrollSelectedToCenterFromView"
      leftBtnIcon={<TabItemLeftArrowIcon />}
      rightBtnIcon={<TabItemRightArrowIcon />}
      navBtnClassName="navBtnStyles"
      leftNavBtnClassName={isStartingPosition ? 'navBtnOff leftNavBtnClassStyles ' : 'leftNavBtnClassStyles'}
      rightNavBtnClassName={isEndingPosition ? 'navBtnOff rightNavBtnClassStyles' : 'rightNavBtnClassStyles'}
      tabsUpperContainerClassName="tabsUpperContainerStyles"
      tabsContainerClassName="navBtnStyles"
      activeTab={activeTab}
      onTabClick={onTabClick}
      hideNavBtnsOnMobile={false}
      tabsScrollAmount={3}
      didReachStart={(val: boolean) => setIsStartingPosition(val)}
      didReachEnd={(val: boolean) => setIsEndPosition(val)}
    >
      {tabs.map(({ titleText, coreUrl, urlPage, isChosen }) => {
        return (
          <Tab key={titleText} className="tabsContainerStyles">
            <TypeTitle isChosen={isChosen} onClick={() => navigate(`${coreUrl}${urlPage}`)}>
              {titleText}
            </TypeTitle>
          </Tab>
        );
      })}
    </Tabs>
  );
};
