import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { UpdateMeRequestType } from '../authTypes';
import { updateMe } from '../index';
import { ErrorType } from './types';

export const useUpdateMeMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.updateMeMutate],
    mutationFn: async (userAccountData: Partial<UpdateMeRequestType>) => {
      const { data } = await updateMe(userAccountData);

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
