/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleAnalytics } from '../../../../analytics';
import { useLoginMutate } from '../../../../api/auth/hooks';
import { QUERY_KEY } from '../../../../common';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { LinkRouter } from '../../../../componentUI/simple/Link';
import { Text } from '../../../../componentUI/simple/Text';
import { ToggleSwitch } from '../../../../componentUI/simple/ToggleSwitch';
import { NotificationType } from '../../../../componentUI/types';
import { Loader, TermsBlock } from '../../../../components';
import { validateEmail } from '../../../../helpers/validate';
import { useFormField, useLanguageSwitcherButton, useMatchMedia } from '../../../../hooks';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { AuthContainer } from '../../Auth';
import { GoogleLogo, LockIcon, MailIcon } from '../../assets';
import { LogoBlock, TitleBlock } from '../../components';
import { generateGoogleAuthUrl } from '../../helpers/generateGoogleAuthUrl';
import { parseSearchParamToString } from '../../helpers/parseSearchParamToString';
import { usePasswordIconManage } from '../../hooks/usePasswordIconManage';
import { OTPCode } from '../OTPCode';
import {
  LeftColumnStyled,
  RightColumnStyled,
  SeparatorStyled,
  WrapperInputStyled,
  WrapperPasswordStyled,
  WrapperSeparatorStyled,
  WrapperSignInStyled,
  WrapperSignUpStyled,
  WrapperTextStyled,
  WrapperToggleStyled,
} from './styled';

const MINIMUM_NUMBER_CHARACTERS = 8;

/** Страница входа на сайт */

export const SignIn = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();
  const client = useQueryClient();
  const { LanguageSwitcher } = useLanguageSwitcherButton();

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const googleError = searchParams.get('error');
  const googleErrorText = googleError ? parseSearchParamToString(googleError) : t('auth.signInPage.googleErrorDefault');

  const {
    mutate: login,
    data: loginData,
    isSuccess: isSuccessLoginData,
    isLoading: isLoadingLoginData,
  } = useLoginMutate();

  const [email, setEmail, emailError, setEmailError] = useFormField({ initValue: '', withError: true });
  const [password, setPassword, passwordError, setPasswordError] = useFormField({ initValue: '', withError: true });
  const { rightIconPassword, passTypeInput } = usePasswordIconManage(password);

  const [rememberMe, setRememberMe] = useState(false);

  const isDisableSingInBtn = Boolean(passwordError || emailError || password.length === 0 || email.length === 0);

  const accountNotVerified = isSuccessLoginData && !loginData.result;

  const changeEmail = (email: string) => {
    if (!validateEmail(email) || email.length === 0) {
      setEmailError(t('auth.signInPage.invalidEmail'));
      setEmail(email);
    } else {
      setEmailError('');
      setEmail(email);
    }
  };

  const changePassword = (password: string) => {
    if (password.length < MINIMUM_NUMBER_CHARACTERS || password.length === 0) {
      setPasswordError(t('auth.signInPage.invalidPassword'));
      setPassword(password);
    } else {
      setPasswordError('');
      setPassword(password);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      changeEmail(email);
      changePassword(password);

      if (!isDisableSingInBtn) {
        login({
          email,
          password,
          rememberMe,
        });
      }
    }
  };

  useEffect(() => {
    // проверка на то что юзер не из категории Shopify
    if (loginData && loginData.result) {
      // сохраняем валидный токен
      Cookies.set('jwt', loginData.token);
      Cookies.set('jwt-refresh', loginData.refreshToken);
      // перезапрашиваем список доступных url
      client.invalidateQueries({ queryKey: [QUERY_KEY.registerUser] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAccountInfo] });

      navigate('/dashboard');
    }
  }, [isSuccessLoginData]);

  useEffect(() => {
    if (googleError) {
      dispatch(
        addToast({
          text: googleErrorText,
          type: NotificationType.Default,
        }),
      );
    }
  });

  if (isLoadingLoginData) {
    return <Loader style={{ margin: '45vh auto' }} />;
  }

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LanguageSwitcher />

        <LogoBlock />

        {accountNotVerified && <OTPCode email={email} />}

        {!accountNotVerified && (
          <WrapperSignInStyled>
            <TitleBlock titleText={t('auth.signInPage.title')} subtitleText={t('auth.signInPage.subtitle')} />

            <WrapperInputStyled>
              <InputWithError
                value={email}
                onChange={(event) => setEmail(event)}
                onBlur={(event) => changeEmail(event.currentTarget.value)}
                placeholder={t('auth.signInPage.emailPlaceholder')}
                errorMessage={emailError}
                type="email"
                leftIcon={<MailIcon fill={email ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
              />

              <InputWithError
                value={password}
                onChange={(event) => setPassword(event)}
                onBlur={(event) => changePassword(event.currentTarget.value)}
                placeholder={t('auth.signInPage.passwordPlaceholder')}
                errorMessage={passwordError}
                type={passTypeInput}
                leftIcon={<LockIcon fill={password ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
                rightIcon={rightIconPassword}
                onKeyDown={handleKeyDown}
              />

              <WrapperPasswordStyled>
                <WrapperToggleStyled>
                  <ToggleSwitch
                    id="toggle"
                    size="xs"
                    value={rememberMe}
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <Text colorText={COLOR_MAP.text.white}>{t('auth.signInPage.rememberMe')}</Text>
                </WrapperToggleStyled>

                <LinkRouter to="/forgot-password">{t('auth.signInPage.forgotPassword')}</LinkRouter>
              </WrapperPasswordStyled>

              <Button
                isStretch
                onClick={() =>
                  login({
                    email,
                    password,
                    rememberMe,
                  })
                }
                disabled={isDisableSingInBtn}
              >
                {t('auth.signInPage.signInButton')}
              </Button>
            </WrapperInputStyled>

            <WrapperSeparatorStyled>
              <SeparatorStyled />
              <Text style={{ flex: '1 0 auto' }} colorText={COLOR_MAP.text.white}>
                {t('auth.signInPage.orText')}
              </Text>
              <SeparatorStyled />
            </WrapperSeparatorStyled>

            <SecondButton style={{ gap: '10px' }} isStretch linkButton={generateGoogleAuthUrl('signin')}>
              <img src={GoogleLogo} width={24} height={24} alt="google logo" />
              {t('auth.signInPage.signInWithGoogle')}
            </SecondButton>

            <WrapperSignUpStyled>
              <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                {t('auth.signInPage.noAccountText')}
              </Text>

              <WrapperTextStyled>
                <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                  {t('auth.signInPage.signUpText')}
                </Text>
                &nbsp;
                <LinkRouter to="/signup">{t('auth.signInPage.signUp')}</LinkRouter>
              </WrapperTextStyled>
            </WrapperSignUpStyled>
          </WrapperSignInStyled>
        )}

        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && <LeftColumnStyled />}
    </AuthContainer>
  );
};
