import React from 'react';
import styled from 'styled-components';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { SurveyDoneIcon } from '../../../../common/icons/SvgInline/SvgIcons_11';
import { TAG_NAME } from '../../../../componentUI/constants';

const ThxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const NpsThanks = () => {
  return (
    <ThxContainer>
      <SurveyDoneIcon />
      <Title size={TITLE_SIZE.h6} tag={TAG_NAME.h6}>
        Thanks for taking the survey!
      </Title>
    </ThxContainer>
  );
};
