import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Label = styled.label<{ isError: boolean }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ isError }) => (isError ? COLOR_MAP.accent.red : COLOR_MAP.text.darkGrey)};
`;

export const StyledTextarea = styled.textarea<{ isError: boolean; isFocused: boolean }>`
  resize: none;

  width: 100%;
  height: 96px;
  padding: 10px 12px;

  background-color: ${COLOR_MAP.controls.textAndIcon};
  border: 1px solid ${({ isError }) => (isError ? COLOR_MAP.accent.red : COLOR_MAP.controls.strokeNormal)};
  border-radius: 4px;

  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    border-color: ${({ isError }) => (isError ? COLOR_MAP.accent.red : COLOR_MAP.controls.strokeHover)};
  }

  &:focus {
    outline: none;
    border-color: ${({ isError }) => (isError ? COLOR_MAP.accent.red : COLOR_MAP.controls.dark.clickableHover)};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${COLOR_MAP.controls.strokeNormal};
    background: ${COLOR_MAP.controls.fillDisabled};
  }

  &::placeholder {
    color: ${COLOR_MAP.controls.placeholder};
    text-align: left;
  }
`;

export const Description = styled.div<{ isError: boolean }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ isError }) => (isError ? COLOR_MAP.accent.red : COLOR_MAP.text.darkGrey)};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ErrorDescription = styled.span`
  color: ${COLOR_MAP.accent.red};
`;
