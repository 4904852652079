import styled from 'styled-components';

import { Z_INDEX_LAYERS } from '../../../../componentUI';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const HelpCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  scrollbar-width: none;

  ${truthfulAdaptive.maxWidth.tablet} {
    // для позиционирования блока HelpCard по макету
    order: -1;

    flex-direction: row;
    justify-content: flex-start;

    overflow-x: auto;

    ::-webkit-scrollbar {
      width: 0;
    }

    // перебиваем стили для отключения полосы прокрутки у блока
    &::-webkit-scrollbar-thumb {
      background: none;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;

const LighteningLeft = styled.div<{ height: string }>`
  position: absolute;
  left: 0;

  height: ${({ height }) => height};
  width: 64px;

  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  z-index: ${Z_INDEX_LAYERS.other};

  ${truthfulAdaptive.maxWidth.tablet} {
    left: 18px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    left: 35px;
  }

  @media (max-width: 358px) {
    left: 0;
  }
`;

const LighteningRight = styled.div<{ height: string }>`
  position: absolute;
  right: 15px;

  height: ${({ height }) => height};
  width: 64px;

  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  z-index: ${Z_INDEX_LAYERS.other};
`;

const LoyaltySkeletonWrapperStyled = styled.div`
  width: 100%;
  height: 80px;

  ${truthfulAdaptive.maxWidth.tablet} {
    width: clamp(128px, 32vw, 310px);
    height: auto;
    flex-shrink: 0;
  }
`;

export { HelpCardStyled, LighteningLeft, LighteningRight, LoyaltySkeletonWrapperStyled };
