import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import {
  UserLoyaltyInfoRequestType,
  UserLoyaltyInfoResponseNewType,
  UserLoyaltySmallInfoResponseNewType,
} from './types';

export const getUserLoyaltyInfo = (data: UserLoyaltyInfoRequestType) => {
  const { localeName } = data;

  return instance.get<UserLoyaltyInfoResponseNewType>(`user-loyalty-bonuses?localeName=${localeName}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getUserLoyaltySmallInfo = (data: UserLoyaltyInfoRequestType) => {
  const { localeName } = data;

  return instance.get<UserLoyaltySmallInfoResponseNewType>(`auth/loyalty-level?localeName=${localeName}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const setNotificationReachingLoyaltyLevelShown = () => {
  return instance.patch(
    '/user-info/set-notification-reaching-loyalty-level-shown',
    {},
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};

export const setNotificationAboutAccrualLoyaltyBonusesShown = () => {
  return instance.patch(
    'user-info/set-notification-about-accrual-loyalty-bonuses-shown',
    {},
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
