import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetStatistics } from '../../../../api/product/hooks/useGetStatistics';
import { SkeletonBasic } from '../../../../componentUI/skeleton';
import { ItemFulfilledRequests } from '../ItemFulfilledRequests';
import { OrderStatisticsChart } from '../OrderStatisticsChart';
import { OrderStatistics } from '../../../ColumnMenu/components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { useLocalizedDataCharts } from './constants';
import {
  ChartsInfoStyled,
  ContainerInfoStyled,
  StatisticsFulfilledRequestsStyled,
  StatisticsRequestStatusesStyled,
  SummaryStyled,
  WrapperItemFulfilledRequestsStyled,
  WrapperStatisticsStyled,
} from './styles';

export const Summary = () => {
  const { t } = useTranslation();
  const { data: statisticsOrder, isLoading: isLoadingStatistics } = useGetStatistics();
  const DATA_CHARTS = useLocalizedDataCharts(t);

  return (
    <SummaryStyled>
      <Title tag={TAG_NAME.h3} size={TITLE_SIZE.h3} colorText={COLOR_MAP.text.darkGrey}>
        {t('dashboard.summary.title')}
      </Title>

      {statisticsOrder && (
        <ContainerInfoStyled>
          <ChartsInfoStyled>
            {DATA_CHARTS.map(({ text, id, parameterOne, parameterTwo, chartColor, backgroundChartColor }) => {
              const calculatedPercentage =
                Math.round((statisticsOrder[parameterOne] * 100) / statisticsOrder[parameterTwo]) || 0;

              return (
                <OrderStatisticsChart
                  key={id}
                  text={text}
                  percentage={calculatedPercentage}
                  chartColor={chartColor}
                  backgroundChartColor={backgroundChartColor}
                  isLoadingStatistics={isLoadingStatistics}
                />
              );
            })}
          </ChartsInfoStyled>

          <WrapperStatisticsStyled>
            <StatisticsFulfilledRequestsStyled>
              {isLoadingStatistics && (
                <SkeletonBasic width="160px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              )}

              {!isLoadingStatistics && (
                <ItemFulfilledRequests
                  text={t('dashboard.summary.submittedItems')}
                  quantity={statisticsOrder.submitteditems}
                />
              )}

              <WrapperItemFulfilledRequestsStyled>
                {isLoadingStatistics && (
                  <>
                    <SkeletonBasic width="110px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                    <SkeletonBasic width="80px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  </>
                )}

                {!isLoadingStatistics && (
                  <>
                    <ItemFulfilledRequests
                      text={t('dashboard.summary.authentic')}
                      quantity={statisticsOrder.authentic}
                    />
                    <ItemFulfilledRequests text={t('dashboard.summary.fake')} quantity={statisticsOrder.fake} />
                  </>
                )}
              </WrapperItemFulfilledRequestsStyled>
            </StatisticsFulfilledRequestsStyled>

            <StatisticsRequestStatusesStyled>
              <OrderStatistics
                doNotShow={['drafts']}
                idExternalData={false}
                externalData={statisticsOrder}
                design="dashboard"
                externalIsLoading={isLoadingStatistics}
              />
            </StatisticsRequestStatusesStyled>
          </WrapperStatisticsStyled>
        </ContainerInfoStyled>
      )}
    </SummaryStyled>
  );
};
