import React, { useEffect, useRef, useState } from 'react';
import { HelpCardButton } from '../HelpCardButton';
import { HelpCardStyled, LighteningLeft, LighteningRight, LoyaltySkeletonWrapperStyled } from './styled';
import { useLocalizedHelpCardData } from './constants';
import { useUserLoyaltySmallInfo } from '../../../../api/UserLoyalty/hooks';
import { useSelectedLanguage } from '../../../../hooks';
import { SkeletonBasic } from '../../../../componentUI/skeleton';

export const HelpCard = () => {
  const backgroundInformationRef = useRef<HTMLDivElement>(null);
  const [heightBackgroundInformation, setHeightBackgroundInformation] = useState('');
  const [isLightenLeft, getIsLightenLeft] = useState(false);
  const [isLightenRight, getIsLightenRight] = useState(false);

  const selectedLanguage = useSelectedLanguage();

  const { data, isLoading } = useUserLoyaltySmallInfo({
    localeName: selectedLanguage,
  });

  const DATA_HELP_CARD = useLocalizedHelpCardData(data && data.levelName, data && data.levelDisplayName);

  useEffect(() => {
    if (backgroundInformationRef.current) {
      const height = backgroundInformationRef.current.offsetHeight;
      setHeightBackgroundInformation(`${String(height)}px`);
    }
  }, [backgroundInformationRef]);

  return (
    <HelpCardStyled ref={backgroundInformationRef}>
      {isLightenLeft && <LighteningLeft height={heightBackgroundInformation} />}
      {isLightenRight && <LighteningRight height={heightBackgroundInformation} />}

      {isLoading ? (
        <>
          <LoyaltySkeletonWrapperStyled>
            <SkeletonBasic width="100%" height="100%" />
          </LoyaltySkeletonWrapperStyled>
          {DATA_HELP_CARD.slice(1).map(({ id, bgColor, Icon, text, subText, url, isLocal, isModal }) => (
            <HelpCardButton
              key={id}
              id={id}
              isLocal={isLocal}
              bgColor={bgColor}
              Icon={Icon}
              text={text}
              subText={subText}
              url={url}
              isModal={isModal}
              getIsLightenLeft={getIsLightenLeft}
              getIsLightenRight={getIsLightenRight}
            />
          ))}
        </>
      ) : (
        DATA_HELP_CARD.map(({ id, bgColor, Icon, text, subText, url, isLocal, isModal }) => {
          return (
            <HelpCardButton
              key={id}
              id={id}
              isLocal={isLocal}
              bgColor={bgColor}
              Icon={Icon}
              text={text}
              subText={subText}
              url={url}
              isModal={isModal}
              getIsLightenLeft={getIsLightenLeft}
              getIsLightenRight={getIsLightenRight}
            />
          );
        })
      )}
    </HelpCardStyled>
  );
};
