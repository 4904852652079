import React, { useCallback } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { TrashIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { useMatchMedia } from '../../../hooks';
import { COLOR_MAP } from '../../colorsMap';
import { VisuallyHiddenInput, noop } from '../../utils';
import { BrandedButton } from '../Button';
import { Props } from './Radio';

const RadioContainer = styled.div`
  position: relative;
`;

const RadioWrapper = styled.label<{ isChecked: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  box-sizing: content-box;
  overflow: hidden;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
  ${({ isChecked }) =>
    isChecked ? `border: 2px solid ${COLOR_MAP.text.brandColor};` : `border: 2px solid ${COLOR_MAP.text.white}`};

  user-select: none;
  cursor: pointer;
`;

const WrapperImgStyled = styled.div<{ width?: string | number; height?: string | number }>`
  ${({ width }) => (width ? `width: ${width};` : 'auto;')}
  ${({ height }) => (height ? `height: ${height};` : 'auto;')}
`;

const stylesForButtons: CSSProperties = {
  position: 'absolute',
  right: '-4px',
  top: '-4px',
  padding: '4px',

  borderRadius: '4px',

  backgroundColor: COLOR_MAP.background.white,
  boxShadow: '0px 4px 9px 0px rgba(150, 112, 38, 0.05), 0px 5px 14px 0px rgba(0, 0, 0, 0.08)',
};

type RadioChipsWithPhotoProps = Props & {
  url: string;
  widthPicture?: number | string;
  heightPicture?: number | string;
  widthPictureMobile?: number | string;
  heightPictureMobile?: number | string;
  onDelete?: (value: string) => void;
  stylesBackground?: CSSProperties;
};

export const RadioChipsWithPhoto = ({
  checkedValue,
  disabled,
  onChange = noop,
  onDelete = noop,
  stylesBackground,
  name,
  value,
  url,
  widthPicture = 100,
  heightPicture = 100,
  widthPictureMobile = 148,
  heightPictureMobile = 148,
}: RadioChipsWithPhotoProps) => {
  const { isMobile } = useMatchMedia();

  const isChecked = checkedValue === value;

  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const deleteHandler = useCallback(() => {
    onDelete(value);
  }, [onDelete, value]);

  return (
    <RadioContainer>
      {onDelete !== noop ? (
        <BrandedButton style={stylesForButtons} onClick={deleteHandler}>
          <TrashIcon />
        </BrandedButton>
      ) : null}

      <RadioWrapper style={stylesBackground} disabled={disabled} isChecked={isChecked}>
        <WrapperImgStyled
          width={isMobile ? widthPictureMobile : widthPicture}
          height={isMobile ? heightPictureMobile : heightPicture}
        >
          <img style={{ objectFit: 'contain' }} src={url} alt="photos" width="100%" height="100%" />
        </WrapperImgStyled>

        <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={isChecked} name={name} value={value} />
      </RadioWrapper>
    </RadioContainer>
  );
};
