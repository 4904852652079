import styled, { CSSProperties } from 'styled-components';

import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';

const ConditionsWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;

  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 1fr;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;

    padding: 24px 20px;
  }
`;

const TextAgreementBlock = styled.div`
  max-width: 1600px;
  padding: 60px 160px 80px 160px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1600px) {
    padding: 60px calc(5px + (160 - 5) * ((100vw - 700px) / (1600 - 700))) 80px
      calc(5px + (160 - 5) * ((100vw - 700px) / (1600 - 700)));
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 0;
  }
`;

const NavigationBlockWrapper = styled.div`
  padding: 50px 28px 80px 28px;

  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const NavigationBlock = styled.div`
  position: fixed;

  padding: 0 28px 0 0;
  max-width: 250px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LastUpdateBlock = styled.div`
  margin: 40px 0 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  border: 1px solid ${COLOR_MAP.accent.green};
  border-radius: 6px;

  color: ${COLOR_MAP.accent.green};
`;

const ClauseWrapper = styled.div`
  margin: 12px 0 0 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PassageWrapper10 = styled.div`
  display: flex;
  gap: 10px;
`;

const PassageWrapper11 = styled.div`
  display: flex;
  gap: 11px;
`;

const PassageWrapper12 = styled.div`
  display: flex;
  gap: 12px;
`;

const PassageWrapper20 = styled.div`
  display: flex;
  gap: 20px;
`;

const ParagraphWrapper20 = styled.div`
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ParagraphWrapper40 = styled.div`
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ParagraphWrapper45 = styled.div`
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MobileNavigationBlockWrapper = styled.div`
  margin: 0 0 24px 0;
  justify-content: space-between;
  display: flex;
  gap: 20px;
`;

const stylesForButton: CSSProperties = {
  justifyContent: 'flex-start',
  padding: 0,

  backgroundColor: 'transparent',

  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

const stylesForBlockLink: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '24px 1fr',
  gap: '8px',
};

export {
  ConditionsWrapper,
  TextAgreementBlock,
  NavigationBlockWrapper,
  NavigationBlock,
  LastUpdateBlock,
  ClauseWrapper,
  MobileNavigationBlockWrapper,
  PassageWrapper10,
  PassageWrapper11,
  PassageWrapper12,
  PassageWrapper20,
  ParagraphWrapper20,
  ParagraphWrapper40,
  ParagraphWrapper45,
  stylesForButton,
  stylesForBlockLink,
};
