/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { TAG_NAME } from '../../componentUI/constants';
import { Text, TEXT_SIZE } from '../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';
import { ToggleSwitchV2 } from '../../componentUI/simple/ToggleSwitchV2';
import { InfoTooltip } from '../../componentUI/simple/Tooltip';
import { ManageTemplates, TemplateCertificate } from './containers';
import { CustomCertificateStyled, WrapperTextStyled, WrapperTitleStyled } from './styled';
import { Props } from './types';

// showCustomCertificate - хранит состояние тогла доступности функционала выпуска кастомных сертификатов
// isUserCustomCertificate - у пользователя есть ранее созданные шаблоны сертификатов

export const CustomCertificate = ({
  isFinancialGuaranteeUsed,
  userCertificateTemplateId,
  setUserCertificateTemplateId,
}: Props) => {
  const { t } = useTranslation();

  const [showCustomCertificate, setShowCustomCertificate] = useState(false);
  const [isUserCustomCertificate, setIsUserCustomCertificate] = useState(true);

  // если сертификат не выбран или выбран стандарт Standard
  // бэк ждет в переменной userCertificateTemplateId значение null
  useEffect(() => {
    if (!showCustomCertificate) {
      setUserCertificateTemplateId(null);
    }
  }, [showCustomCertificate]);

  // При выборе к заказу финансовой гарантии, логика выпуска сертификата
  // отличается от стандартной, выполняется на стороне бэка
  useEffect(() => {
    if (isFinancialGuaranteeUsed) {
      setUserCertificateTemplateId(null);
      setShowCustomCertificate(false);
    }
  }, [isFinancialGuaranteeUsed]);

  // управляет состоянием тогда, когда заказ открывается в режиме черновика
  useEffect(() => {
    if (userCertificateTemplateId) {
      setShowCustomCertificate(true);
    }
  }, [userCertificateTemplateId]);

  if (isFinancialGuaranteeUsed) {
    return null;
  }

  return (
    <CustomCertificateStyled>
      <WrapperTitleStyled>
        <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5}>
          {t('customCertificate.title')}
        </Title>

        <InfoTooltip
          styleTooltip={{ padding: '8px', left: '-108px' }}
          widthTooltip="236px"
          text={<Text size={TEXT_SIZE.sub}>{t('customCertificate.tooltip')}</Text>}
        />
      </WrapperTitleStyled>

      <ToggleSwitchV2
        label={
          <WrapperTextStyled>
            <Text>{t('customCertificate.ToggleSwitchText1')}</Text>&nbsp;
            <Text colorText={COLOR_MAP.text.darkGreyV2}>{t('customCertificate.ToggleSwitchText2')}</Text>
          </WrapperTextStyled>
        }
        isChecked={showCustomCertificate}
        onToggle={setShowCustomCertificate}
      />

      {showCustomCertificate && (
        <TemplateCertificate
          setIsUserCustomCertificate={setIsUserCustomCertificate}
          showCustomCertificate={showCustomCertificate}
          setUserCertificateTemplateId={setUserCertificateTemplateId}
          userCertificateTemplateId={userCertificateTemplateId}
        />
      )}

      {!isUserCustomCertificate && showCustomCertificate && (
        <ManageTemplates isOpenAddCustomCertificate={!isUserCustomCertificate} />
      )}
    </CustomCertificateStyled>
  );
};
