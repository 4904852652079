import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { RegisterRequestType } from '../authTypes';
import { register } from '../index';
import { ErrorType } from './types';

export const useRegisterMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.registerUser],
    mutationFn: async (registerData: RegisterRequestType) => {
      const data = await register(registerData);

      return data;
    },

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
