import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  max-width: 420px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

export { Wrapper, SelectWrapper };
